import React, { useState } from "react";
import {
  Box,
  Button,
  HStack,
  Avatar,
  Text,
  VStack,
  Badge,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Flex,
  Stack,
} from "@chakra-ui/react";
import { FaPhoneAlt, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import { FormattedMessage } from "react-intl";
import ContactForm from "../ContactForm";

const AgentInfo = ({ agent, property, auth }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showCallInfo, setShowCallInfo] = useState(false);
  const [disable, setDisable] = useState(false);

  const handleCallClick = () => {
    setShowCallInfo(true);
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = agent?.phone || "971547562839";
    const message = `Hello, I am interested in this property: ${property?.reference_number}`;
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <Box p={{ base: 3, lg: 5 }} borderRadius="md" border="1px" borderColor="gray.200">
      {!showCallInfo && (
        <Stack direction={{ base: "column", lg: "row" }} spacing={4} mb={4} align="center">
          <Button
            leftIcon={<FaPhoneAlt />}
            colorScheme="red"
            size={{ base: "md", lg: "lg" }}
            onClick={handleCallClick}
            width={{ base: "100%", lg: "auto" }}
          >
            <FormattedMessage id="agentInfo.callButton" defaultMessage="Call" />
          </Button>
          <Button
            leftIcon={<FaEnvelope />}
            colorScheme="red"
            size={{ base: "md", lg: "lg" }}
            onClick={onOpen}
            width={{ base: "100%", lg: "auto" }}
          >
            <FormattedMessage id="agentInfo.emailButton" defaultMessage="Email" />
          </Button>
          <Button
            leftIcon={<FaWhatsapp />}
            colorScheme="green"
            size={{ base: "md", lg: "lg" }}
            onClick={handleWhatsAppClick}
            width={{ base: "100%", lg: "auto" }}
          >
            <FormattedMessage id="agentInfo.whatsappButton" defaultMessage="WhatsApp" />
          </Button>
        </Stack>
      )}

      {showCallInfo && (
        <Box mb={4} p={4} border="1px" borderColor="gray.200" borderRadius="md" bg="gray.50">
          <Flex direction={{ base: "column", xl: "row" }} align="center" justifyContent="space-between">
            <HStack spacing={4}>
              <Icon as={FaPhoneAlt} color="red.500" boxSize={{ base: 5, xl: 6 }} />
              <Text fontSize={{ base: "lg", xl: "xl" }} fontWeight="bold" color="red.500">
                {agent?.phone || "+971547562839"}
              </Text>
            </HStack>

            <Stack direction={{ base: "column", xl: "row" }} spacing={4} mt={{ base: 4, xl: 0 }}>
              <Button
                leftIcon={<FaEnvelope />}
                variant="outline"
                colorScheme="red"
                size={{ base: "md", xl: "lg" }}
                onClick={onOpen}
              >
                <FormattedMessage id="agentInfo.emailButton" defaultMessage="Email" />
              </Button>
              <Button
                leftIcon={<FaWhatsapp />}
                variant="outline"
                colorScheme="green"
                size={{ base: "md", xl: "lg" }}
                onClick={handleWhatsAppClick}
              >
                <FormattedMessage id="agentInfo.whatsappButton" defaultMessage="WhatsApp" />
              </Button>
            </Stack>
          </Flex>

          <Text mt={2} fontSize={{ base: "md", lg: "lg" }}>
            <Text as="span" fontWeight="bold">
              {agent?.name || <FormattedMessage id="agentInfo.defaultAgentName" defaultMessage="Agent Name" />}
            </Text>
            <FormattedMessage id="agentInfo.languageSpoken" defaultMessage=" speaks English" />
          </Text>
          <Text mt={1} fontSize={{ base: "md", lg: "lg" }}>
            <FormattedMessage id="agentInfo.referenceNote" defaultMessage="Mention the reference:" />{" "}
            <Text as="span" fontWeight="bold">
              {property?.reference_number || "MCC-S-38033"}
            </Text>
          </Text>
        </Box>
      )}

      <HStack spacing={4} align="center">
        <Avatar size={{ base: "md", lg: "lg" }} name={agent?.name} src={agent?.photo} />
        <VStack align="start" spacing={0}>
          <HStack>
            <Text fontSize={{ base: "md", lg: "lg" }} fontWeight="bold">
              {agent?.name || <FormattedMessage id="agentInfo.defaultAgentName" defaultMessage="Chris Watson" />}
            </Text>
            <Badge colorScheme="purple" fontSize={{ base: "xs", lg: "sm" }}>
              <FormattedMessage id="agentInfo.noRatings" defaultMessage="No ratings" />
            </Badge>
          </HStack>
          <Text fontSize={{ base: "xs", lg: "sm" }} color="gray.500">
            <FormattedMessage id="agentInfo.responseTime" defaultMessage="Usually responds within 5 minutes" />
          </Text>
        </VStack>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full", lg: "md" }} zIndex={1500}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <FormattedMessage
              id="agentInfo.contactAgent"
              defaultMessage="Contact {agentName}"
              values={{ agentName: agent?.name || <FormattedMessage id="agentInfo.defaultAgentName" defaultMessage="Agent" /> }}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ContactForm
              agent={agent}
              auth={auth}
              property={property}
              onClose={onClose}
              setDisable={setDisable}
              disable={disable}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AgentInfo;
