import React, { useCallback, useState, Suspense } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { fetchPropertyList } from '../../api/Property/propertyApi'; 
import Header from './Header';
import Carousel from '../common/Carousal'; 
import FullMapView from './FullMapView'; 
import TownPreview from './TownPreview'; 

const locationOption = [
  { community: 'Mohamed Bin Zayed City' },
  { community: 'Al Reem Island' },
  { community: 'Al Raha Beach' },
  { community: 'Mussafah' },
  { community: 'Sas Al Nakheel' },
  { community: 'Al Bateen' },
  { community: 'Masdar City' },
  { community: 'Tourist Club Area' },
  { community: 'Saadiyat Island' },
  { community: 'Khalifa City' },
  { community: 'Al Khalidiya' },
  { community: 'Corniche Road' },
  { community: 'Electra Street' },
  { community: 'The Marina' },
  { community: 'Yas Island' },
  { community: 'Grand Mosque District' },
  { community: 'Hamdan Street' },
  { community: 'Eastern Road' },
];

const ExploreTowns = () => {
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [selectedTown, setSelectedTown] = useState(null);

  const { data: propertyList, isLoading } = useQuery({
    queryKey: ['FullPropertyList'],
    queryFn: fetchPropertyList, 
    select: (res) => res?.data?.records,
  });

  const getPropertyCountForTown = (communityName) => {
    return propertyList?.filter(property => property.community === communityName).length || 0;
  };

  const openMap = useCallback((town) => {
    setSelectedTown(town); 
    setIsMapOpen(true); 
  }, []);

  const closeMap = useCallback(() => {
    setIsMapOpen(false); 
    setSelectedTown(null);
  }, []);

  return (
    <Box backgroundColor="white" pt={20} pb={10}>
      <Box maxWidth="1570px" mx="auto" p={6}>
        <Box pl={10} ml={5}>
          <Header />
        </Box>
        <Box my={10}>
          {!isMapOpen ? (
            <Suspense fallback={<Spinner size="xl" />}>
              <Carousel>
                {locationOption?.map((town, index) => (
                  <Box key={index} onClick={() => openMap(town)} m="30px">
                    <TownPreview
                      townName={town?.community} 
                      numProperties={getPropertyCountForTown(town.community)} 
                    />
                  </Box>
                ))}
              </Carousel>
            </Suspense>
          ) : (
            <FullMapView town={selectedTown} propertyList={propertyList} onClose={closeMap} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ExploreTowns;
