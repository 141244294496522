import React from "react";
import { UserProfile } from "../../components/UserProfile/index.js";
import Header from "../../components/Header";

const DashboardProfile = () => {
  return (
    <>
      <Header />
      <div style={{ position: "relative" }}>
        <div
          style={{
            backgroundColor: "#272726",
            height: "81px",
            position: "fixed",
            top: "0px",
            width: "100%",
            zIndex: 1,
          }}
        ></div>
        <UserProfile />
      </div>
    </>
  );
};

export default DashboardProfile;
