import React from "react";
import { Modal } from "antd";
import { FormattedMessage } from "react-intl";

const SuccessModal = ({ isModalVisible, onClose }) => (
  <Modal
    open={isModalVisible}
    onCancel={onClose}
    onOk={onClose}
    bodyStyle={{
      height: "100px",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    }}
    okButtonProps={{ style: { backgroundColor: "#E5BE54" } }}
    zIndex={3000} 
  >
    <p style={{ fontSize: "20px", width: "100%", textAlign: "center" }}>
      <FormattedMessage id="we_have_received" defaultMessage="We have received your inquiry!" />
    </p>
  </Modal>
);

export default SuccessModal;
