import { Row, Col } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import "../../styles/footer.css";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="footer-divider-container"></div>
      <Row className="footer-main-container">
        <Col xs={24} lg={12} className="footer-left">
          <div onClick={() => navigate("/")}>
            <img
              alt="logo"
              src="/icons/Logo Colored.svg"
              style={{ cursor: "pointer", width: '250px' }}
            />
          </div>
          <p className="customer-support">
            <FormattedMessage id="footer.customer_support" defaultMessage="Total Free Customer Care" />
            <br />+088 123 456 789
          </p>
          <p className="live-support">
            <FormattedMessage id="footer.live_support" defaultMessage="Live Support" />
            <br />hi@sandseekers.ae
          </p>
          <h3>
            <FormattedMessage id="footer.social_media_follow" defaultMessage="Follow us on social media" />
          </h3>
          <div className="social-media-links">
            <a href="https://www.facebook.com/profile.php?id=61560022119418"><img src="/icons/facebook.svg" alt="Facebook" className="social-icon" /></a>
            <a href="https://www.linkedin.com/company/sand-seekers-real-estate-llc/"><img src="/icons/linkedin.webp" alt="Linkedin" className="social-icon" /></a>
            <a href="https://www.instagram.com/sandseekersrealestatellc/"><img src="/icons/instagram.png" alt="Instagram" className="social-icon" /></a>
          </div>
        </Col>
        <Col xs={24} lg={12} className="footer-right">
          <div className="footer-listing-alignment newsletter-section">
            <span className="footer-heading">
              <FormattedMessage id="footer.newsletter_heading" defaultMessage="Keep Yourself Up to Date" />
            </span>
            <div className="newsletter-input-container">
              <input
                type="email"
                placeholder="Your email"
                className="email-input"
              />
              <button className="subscribe-button">
                <FormattedMessage id="footer.subscribe" defaultMessage="Subscribe" />
              </button>
            </div>
          </div>
          <div className="footer-listing-container">
            <div className="footer-listing-alignment">
              <span className="footer-heading">
                <FormattedMessage id="footer.popular_search" defaultMessage="Popular Search" />
              </span>
              <ul className="footer-list">
                <li className="footer-link">
                  <FormattedMessage id="footer.apartment_for_sale" defaultMessage="Apartment for Sale" />
                </li>
                <li className="footer-link">
                  <FormattedMessage id="footer.apartment_for_rent" defaultMessage="Apartment for Rent" />
                </li>
                <li className="footer-link">
                  <FormattedMessage id="footer.offices_for_sale" defaultMessage="Offices for Sale" />
                </li>
                <li className="footer-link">
                  <FormattedMessage id="footer.offices_for_rent" defaultMessage="Offices for Rent" />
                </li>
              </ul>
            </div>
            <div className="footer-listing-alignment">
              <span className="footer-heading">
                <FormattedMessage id="footer.quick_links" defaultMessage="Quick Links" />
              </span>
              <ul className="footer-list">
                <li className="footer-link"><FormattedMessage id="footer.terms_of_use" defaultMessage="Terms of Use" /></li>
                <li className="footer-link"><FormattedMessage id="footer.privacy_policy" defaultMessage="Privacy Policy" /></li>
                <li className="footer-link"><FormattedMessage id="footer.pricing_plans" defaultMessage="Pricing Plans" /></li>
                <li className="footer-link"><FormattedMessage id="footer.our_services" defaultMessage="Our Services" /></li>
                <li className="footer-link"><FormattedMessage id="footer.contact" defaultMessage="Contact" /></li>
                <li className="footer-link"><FormattedMessage id="footer.careers" defaultMessage="Careers" /></li>
                <li className="footer-link"><FormattedMessage id="footer.faqs" defaultMessage="FAQs" /></li>
              </ul>
            </div>
            <div className="footer-listing-alignment">
              <span className="footer-heading">
                <FormattedMessage id="footer.discovery" defaultMessage="Discovery" />
              </span>
              <ul className="footer-list">
                <li className="footer-link"><FormattedMessage id="footer.abu_dhabi" defaultMessage="Abu Dhabi" /></li>
                <li className="footer-link"><FormattedMessage id="footer.dubai" defaultMessage="Dubai" /></li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
