import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Carousel, Modal } from "antd";
import { CameraOutlined, CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { bucket_base_url } from "../../../constants/constant";

const ImageCarousel = ({ images }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef(null);

  const handleOpenModal = (index) => {
    setCurrentSlide(index); 
    setIsModalVisible(true); 
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleThumbnailClick = (index) => {
    setCurrentSlide(index);
    carouselRef.current.goTo(index); 
  };

  const goToPreviousSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
      setCurrentSlide((prevSlide) => (prevSlide > 0 ? prevSlide - 1 : images.length - 1));
    }
  };

  const goToNextSlide = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }
  };

  useEffect(() => {
    if (isModalVisible && carouselRef.current) {
      carouselRef.current.goTo(currentSlide);
    }
  }, [isModalVisible, currentSlide]);

  return (
    <>
      <div className="carousel-container">
        <Row gutter={16} style={{ display: "flex", justifyContent: "center" }}>
          <Col xs={24} sm={16} style={{ position: "relative" }}>
            <img
              className="carousel-image-large"
              src={
                images[0]?.filePath?.includes("http")
                  ? images[0]?.filePath
                  : `${bucket_base_url}${images[0]?.filePath}`
              }
              alt="Property large"
              onClick={() => handleOpenModal(0)} 
            />
            <div className="image-count" onClick={() => handleOpenModal(0)}>
              <CameraOutlined style={{ fontSize: '24px', color: 'white' }} />
              <span>{images?.length} Photos</span>
            </div>
          </Col>
          <Col xs={24} sm={8}>
            <Row gutter={[0, 16]}>
              {images.slice(1, 3).map((image, index) => (
                <Col key={index} span={24}>
                  <img
                    className="carousel-image-small"
                    src={
                      image?.filePath?.includes("http")
                        ? image?.filePath
                        : `${bucket_base_url}${image?.filePath}`
                    }
                    alt={`Property small ${index + 1}`}
                    onClick={() => handleOpenModal(index + 1)} 
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>

      <Modal
        open={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width="100vw"
        height="100vh"
        bodyStyle={{ padding: 0, backgroundColor: 'black', overflow: 'hidden' }}
        className="image-gallery-modal"
        style={{
          top: 0,
          overflow: 'hidden',
          maxWidth: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
        }}
        closeIcon={<CloseOutlined className="close-icon" />}
      >
        <div className="modal-content-wrapper" style={{ overflow: 'hidden' }}>
          <div className="carousel-navigation">
            <LeftOutlined onClick={goToPreviousSlide} className="carousel-arrow" style={{ color: 'white', fontSize: '24px', position: 'absolute', left: '10px', top: '50%' }} />
            <RightOutlined onClick={goToNextSlide} className="carousel-arrow" style={{ color: 'white', fontSize: '24px', position: 'absolute', right: '10px', top: '50%', marginRight: '30px' }} />
          </div>
          
          <Carousel
            ref={carouselRef}
            dots={false}
            autoplay={false}
            infinite
            className="carousel-fullscreen"
            arrows
          >
            {images.map((image, index) => (
              <div key={index} className="fullscreen-slide">
                <img
                  className="modal-image"
                  src={
                    image?.filePath?.includes("http")
                      ? image?.filePath
                      : `${bucket_base_url}${image?.filePath}`
                  }
                  alt={`Property ${index}`}
                />
              </div>
            ))}
          </Carousel>

          <div className="thumbnails">
            {images.map((image, index) => (
              <div key={index} className="thumbnail-wrapper">
                {index === currentSlide && (
                  <LeftOutlined onClick={goToPreviousSlide} className="thumbnail-arrow" style={{ color: 'white', fontSize: '18px', position: 'absolute', left: '-20px', top: '50%' }} />
                )}
                <img
                  className={`thumbnail ${currentSlide === index ? 'active' : ''}`}
                  src={
                    image?.filePath?.includes("http")
                      ? image?.filePath
                      : `${bucket_base_url}${image?.filePath}`
                  }
                  alt={`Thumbnail ${index}`}
                  onClick={() => handleThumbnailClick(index)}
                />
                {index === currentSlide && (
                  <RightOutlined onClick={goToNextSlide} className="thumbnail-arrow" style={{ color: 'white', fontSize: '18px', position: 'absolute', right: '-20px', top: '50%' }} />
                )}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageCarousel;
