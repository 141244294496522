import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Home from "../pages/Home";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Properties from "../components/Properties";
import TermsCondition from "../pages/TermsCondition";
import PropertyDetails from "../pages/PropertyDetails";
import UserDashboard from "../pages/user-dashboard";
import { Blogs } from "../pages/blogs";
import BlogDetails from "../pages/blogDetails";
import DashboardProfile from "../pages/user-profile";

export const routes = [
  { path: "/", component: <Home /> },
  { path: "/properties/?", component: <Properties /> },
  { path: "/about-us", component: <AboutUs /> },
  { path: "/contact-us", component: <ContactUs /> },
  { path: "/property-details/:id", component: <PropertyDetails /> },
  { path: "privacy-policy", component: <PrivacyPolicy /> },
  { path: "/terms-of-conditions", component: <TermsCondition /> },
  { path: "/user-dashboard", component: <UserDashboard /> },
  { path: "/blogs", component: <Blogs /> },
  { path: "/blog-details", component: <BlogDetails /> },
  { path: "/edit-profile", component: <DashboardProfile /> },
];
