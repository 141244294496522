import { Button, Col, Form, Input, message, Modal, Row, Typography } from "antd";
import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoginModalVisible } from "../../redux/reducers/globalReducer";
import "../../styles/home.css";
import { LockOutlined, MailOutlined, UserOutlined, LeftOutlined } from "@ant-design/icons";
import { useMutation } from "@tanstack/react-query";
import { LoginUser, registerUser, sendPasswordResetEmail, verifyOtp, resetPassword } from "../../api/auth"; 
import { FormattedMessage } from "react-intl";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { Buffer } from 'buffer';

const { Title } = Typography;

export const Login = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { loginModalVisible } = useSelector((state) => state?.global);
  const [activeTab, setActiveTab] = useState("login");
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [otpVisible, setOtpVisible] = useState(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(120); 
  const [isResendEnabled, setIsResendEnabled] = useState(false);

  const { mutate: mutateRegister, isPending: registerLoading } = useMutation({
    mutationFn: (body) => registerUser(body),
  });
  const { mutate: mutateLogin, isPending: loginLoading } = useMutation({
    mutationFn: (body) => LoginUser(body),
  });
  const { mutate: mutateSendResetEmail, isPending: sendingResetEmail } = useMutation({
    mutationFn: (body) => sendPasswordResetEmail(body),
  });
  const { mutate: mutateVerifyOtp, isPending: verifyingOtp } = useMutation({
    mutationFn: (body) => verifyOtp(body),
  });
  const { mutate: mutateResetPassword, isPending: resettingPassword } = useMutation({
    mutationFn: (body) => resetPassword(body),
  });

  useEffect(() => {
    localStorage.removeItem("otpTimer");
    setOtpVisible(false); 
  }, []);

  const resetTimer = () => {
    setTimer(120);
    setIsResendEnabled(false);
    localStorage.removeItem("otpTimer");
  };

  const handleBackToLogin = () => {
    setForgotPasswordVisible(false);
    setOtpVisible(false);
    setResetPasswordVisible(false);
    resetTimer();
  };
  
  const handleModalClose = () => {
    dispatch(setLoginModalVisible(false));
    handleBackToLogin();
  };

  const handleForgotPassword = (values) => {
    mutateSendResetEmail({ email: values.email }, {
      onSuccess: () => {
        setUserEmail(values.email);
        message.success("Password reset link sent. Check your email.");
        setForgotPasswordVisible(false); 
        setOtpVisible(true);
      },
      onError: () => message.error("Failed to send reset link. Please try again."),
    });
  };

  const handleVerifyOtp = () => {
    const otpString = otp.join('');
    mutateVerifyOtp({ email: userEmail, otp: otpString, type: "forgot_password_otp" }, {
      onSuccess: () => {
        message.success("OTP verified successfully.");
        setOtpVisible(false);
        setResetPasswordVisible(true);
      },
      onError: () => message.error("Invalid OTP. Please try again."),
    });
  };

  const handleResetPassword = (values) => {
    const otpString = otp.join('');
    mutateResetPassword({ email: userEmail, password: values.password, otp: otpString }, {
      onSuccess: () => {
        message.success("Password reset successfully. You can now log in.");
        setResetPasswordVisible(false);   
        setActiveTab("login");
      },
      onError: () => message.error("Failed to reset password. Please try again."),
    });
  };

  const startTimer = (initialTime) => {
    setTimer(initialTime);
    localStorage.setItem("otpTimer", initialTime);
  };

  useEffect(() => {
    if (otpVisible) {
      const savedTime = localStorage.getItem("otpTimer");
      const timeLeft = savedTime ? parseInt(savedTime, 10) : 120;
      startTimer(timeLeft);

      const countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            setIsResendEnabled(true);
            localStorage.removeItem("otpTimer");
            return 0;
          } else {
            localStorage.setItem("otpTimer", prev - 1);
            return prev - 1;
          }
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [otpVisible]);

  const handleResendOtp = () => {
    mutateSendResetEmail({ email: userEmail }, {
      onSuccess: () => {
        message.success("OTP resent. Check your email.");
        setTimer(120);
        setIsResendEnabled(false);
        localStorage.setItem("otpTimer", 120); 
  
        const countdown = setInterval(() => {
          setTimer((prev) => {
            if (prev <= 1) {
              clearInterval(countdown);
              setIsResendEnabled(true);
              localStorage.removeItem("otpTimer");
              return 0;
            } else {
              localStorage.setItem("otpTimer", prev - 1);
              return prev - 1;
            }
          });
        }, 1000);
      },
      onError: () => message.error("Failed to resend OTP. Please try again."),
    });
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    
    if (value.length > 1) { 
      const pastedOtp = value.slice(0, 4).split(""); 
      setOtp(pastedOtp);
      pastedOtp.forEach((digit, idx) => {
        if (document.getElementById(`otp-input-${idx}`)) {
          document.getElementById(`otp-input-${idx}`).value = digit;
        }
      });
    } else if (/^\d?$/.test(value)) { 
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 3) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };
  
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };
  

  const renderBackButton = () => (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <Button
        type="link"
        onClick={handleBackToLogin}
        style={{
          color: "#1890ff",
          fontSize: "16px",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <LeftOutlined style={{ marginRight: "2px" }} />
        Back to login
      </Button>
    </div>
  );

  const handleGoogleLoginSuccess = (response) => {
    const token = response?.credential;
    const decodedToken = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf-8'));
    const email = decodedToken?.email;
    const socialId = decodedToken?.sub;
    const name = decodedToken?.name;

    mutateLogin(
      { token, socialProvider: "google", email, socialId, name },
      {
        onSuccess: (res) => {
          localStorage.setItem("userData", JSON.stringify(res?.data));
          dispatch(setLoginModalVisible(false));
          message.success("Login Successfully");
          window.location.reload();
        },
        onError: () => message.error("Google login failed. Please try again."),
      }
    );
  };

  const handleSubmit = useCallback(
    (values) => {
      let body = { email: values.email, password: values.password };
      if (activeTab === "register") {
        body = { ...body, name: values.name, userType: "Customer", roles: [11] };
        mutateRegister(body, {
          onSuccess: () => {
            form.resetFields();
            mutateLogin({ email: values.email, password: values.password }, {
              onSuccess: (res) => {
                dispatch(setLoginModalVisible(false));
                localStorage.setItem("userData", JSON.stringify(res?.data));
                message.success("Successfully registered");
                window.location.reload();
              },
              onError: (err) => message.error(err?.response?.data?.message),
            });
          },
          onError: (err) => message.error(err?.data?.response?.message),
        });
      } else {
        mutateLogin(body, {
          onSuccess: (res) => {
            form.resetFields();
            dispatch(setLoginModalVisible(false));
            localStorage.setItem("userData", JSON.stringify(res?.data));
            message.success("Login Successfully");
            window.location.reload();
          },
          onError: (err) => message.error(err?.response?.data?.message),
        });
      }
    },
    [activeTab, mutateLogin, mutateRegister, dispatch, form]
  );

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Modal
        open={loginModalVisible}
        onCancel={handleModalClose}
        footer={null}
        destroyOnClose={true}
      >
        {!forgotPasswordVisible && !otpVisible && !resetPasswordVisible ? (
          <>
            <Title level={4}>{activeTab === "login" ? "Login" : "Register"}</Title>
            <Row gutter={16} className="tab-row">
              <Col span={12}>
                <Button
                  className={activeTab === "login" ? "tab-button tab-bg-selected" : "tab-button tab-bg-unselected"}
                  onClick={() => setActiveTab("login")}
                >
                  <FormattedMessage id="Login" />
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className={activeTab === "register" ? "tab-button tab-bg-selected" : "tab-button tab-bg-unselected"}
                  onClick={() => setActiveTab("register")}
                >
                  <FormattedMessage id="Register" />
                </Button>
              </Col>
            </Row>
            <div className="form-align">
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              {activeTab === "register" && (
                <>
                  <Form.Item
                    name="name"
                    label={<FormattedMessage id="Full Name" />}
                    rules={[{ required: true, message: "Please enter name" }]}
                  >
                    <Input
                      placeholder="Full Name"
                      prefix={<UserOutlined />}
                      className="input-style"
                    />
                  </Form.Item>

                  <Form.Item
                    name="username"
                    label={<FormattedMessage id="Username" />}
                    rules={[{ required: true, message: "Please enter username" }]}
                  >
                    <Input
                      placeholder="Username"
                      prefix={<UserOutlined />}
                      className="input-style"
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item
                name="email"
                label={<FormattedMessage id="Email" />}
                rules={[{ required: true, message: "Please enter email" }, { type: "email" }]}
              >
                <Input
                  placeholder="Email"
                  prefix={<MailOutlined />}
                  className="input-style"
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={<FormattedMessage id="Password" />}
                rules={[
                  { required: true, message: "Please enter password" },
                  { min: 8, message: "Must be at least 8 characters" },
                  { pattern: /[A-Z]/, message: "Include an uppercase letter (A-Z)" },
                  { pattern: /[a-z]/, message: "Include a lowercase letter (a-z)" },
                  { pattern: /\d/, message: "Include a number (0-9)" },
                  { pattern: /[!@#$%^&*]/, message: "Include a special character (!@#$%^&*)" },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  prefix={<LockOutlined />}
                  className="input-style"
                />
              </Form.Item>
              {activeTab === "login" && (
                <div style={{ textAlign: "right", marginBottom: "16px" }}>
                  <a onClick={() => setForgotPasswordVisible(true)} style={{ color: "#1890ff", cursor: "pointer" }}>
                    Forgot Password?
                  </a>
                </div>
              )}
              {activeTab === "register" && (
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    { required: true, message: "Please confirm your password!" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject("Passwords do not match!");
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Confirm Password"
                    prefix={<LockOutlined />}
                    className="input-style"
                  />
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  loading={!!registerLoading || !!loginLoading}
                  htmlType="submit"
                  className="tab-button tab-bg-selected"
                >
                  <FormattedMessage id="submit" />
                </Button>
              </Form.Item>
            </Form>
            </div>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <span style={{ fontWeight: "bold", marginBottom: "10px", display: "block" }}>OR</span>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <GoogleLogin
                  onSuccess={handleGoogleLoginSuccess}
                  height="100%"
                  width="100%"
                  theme="outline"
                  ux_mode="popup"
                />
              </div>
            </div>
          </>
        ) : forgotPasswordVisible ? (
          <>
            <Title level={4}>Forgot Password</Title>
            <Form form={form} layout="vertical" onFinish={handleForgotPassword}>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please enter your registered email" }]}
              >
                <Input prefix={<MailOutlined />} placeholder="Email" />
              </Form.Item>
              <Form.Item>
                <Button loading={sendingResetEmail} htmlType="submit" className="tab-button tab-bg-selected">
                  Send Reset Link
                </Button>
              </Form.Item>
            </Form>
            {renderBackButton()}
          </>
        ) : otpVisible ? (
          <>
            <Title level={4}>Enter OTP</Title>
            <Form form={form} layout="vertical" onFinish={handleVerifyOtp}>
              <Form.Item label="OTP" required>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {[0, 1, 2, 3].map((index) => (
                    <Input
                      key={index}
                      id={`otp-input-${index}`}
                      value={otp[index]}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      onKeyDown={(e) => handleKeyDown(e, index)}
                      maxLength={1}
                      style={{ width: '50px', textAlign: 'center' }}
                      onPaste={(e) => handleOtpChange(index, e.clipboardData.getData('Text'))}
                    />
                  ))}
                </div>
              </Form.Item>
              <Form.Item>
                <Button loading={verifyingOtp} htmlType="submit" className="tab-button tab-bg-selected">
                  Verify OTP
                </Button>
              </Form.Item>
            </Form>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              {isResendEnabled ? (
                <Button onClick={handleResendOtp}>Resend OTP</Button>
              ) : (
                <span className="otp-timer">Resend OTP in {`${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`} seconds</span>
              )}
            </div>
            {renderBackButton()}
          </>
        ) : resetPasswordVisible ? (
          <>
            <Title level={4}>Reset Password</Title>
            <Form form={form} layout="vertical" onFinish={handleResetPassword}>
              <Form.Item
                name="password"
                label="New Password"
                rules={[
                  { required: true, message: "Please enter a new password" },
                  { min: 8, message: "Password must be at least 8 characters" },
                  { pattern: /[A-Z]/, message: "Include an uppercase letter (A-Z)" },
                  { pattern: /[a-z]/, message: "Include a lowercase letter (a-z)" },
                  { pattern: /\d/, message: "Include a number (0-9)" },
                  { pattern: /[!@#$%^&*]/, message: "Include a special character (!@#$%^&*)" },
                ]}
              >
                <Input.Password placeholder="New Password" />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                label="Confirm New Password"
                dependencies={["password"]}
                rules={[
                  { required: true, message: "Please confirm your new password" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Passwords do not match!");
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm New Password" />
              </Form.Item>
              <Form.Item>
                <Button loading={resettingPassword} htmlType="submit" className="tab-button tab-bg-selected">
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
            {renderBackButton()}
          </>
        ) : null}
      </Modal>
    </GoogleOAuthProvider>
  );
};
