import React, { useState, useEffect } from 'react';
import {
  Box,
  Input,
  IconButton,
  Tabs,
  TabList,
  Tab,
  InputGroup,
  InputLeftElement,
  VStack,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  SimpleGrid,
  useBreakpointValue,
  List,
  ListItem,
  Flex,
} from '@chakra-ui/react';
import { saveSearchToLocalStorage } from '../../utils/Helper';
import { SearchIcon } from '@chakra-ui/icons';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';
import { setListingQueryParams } from '../../redux/reducers/propertyReducer';
import { propertyType, locationOption } from '../../utils/constant';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

const initialStateData = {
  title_en: '',
  propertyType: '',
  propertyPurpose: '',
  bedroom: '',
  bathroom: '',
  minSize: '',
  maxSize: '',
  community: '',
  completion_status: '',
};

const SearchBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  const propertyPurposeMapping = {
    Rent: 'rent',
    Buy: 'sale',
    Commercial: 'commercial',
  };

  const reversePropertyPurposeMapping = {
    rent: 'Rent',
    sale: 'Buy',
    commercial: 'Commercial'
  };

  const tabMapping = {
    'Rent': 0,
    'Buy': 1,
    'New Projects': 2,
    'Commercial': 3,
  };

  const [stateData, setStateData] = useState(initialStateData);
  const [selectedTab, setSelectedTab] = useState('Rent');
  const [filteredLocations, setFilteredLocations] = useState(locationOption);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isMinMenuOpen, setIsMinMenuOpen] = useState(false);  
  const [isMaxMenuOpen, setIsMaxMenuOpen] = useState(false);  

  const areaOptions = ['500', '1000', '1500', '2000', '2500', '3000', '3500', '4000', '4500', '5000'];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
  
    if (tab) {
      if (tab === 'new_projects') {
        setSelectedTab('New Projects');
      } else if (reversePropertyPurposeMapping[tab]) {
        setSelectedTab(reversePropertyPurposeMapping[tab]);
      }
    }
  }, [location.search]);
  

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
    setStateData(initialStateData);
  
    let updatedState = {};
    if (tabName === 'New Projects') {
      updatedState = { completion_status: 'off_plan_primary', property_purpose: null };
    } else {
      updatedState = { property_purpose: propertyPurposeMapping[tabName], completion_status: null };
    }
  
    dispatch(
      setListingQueryParams({
        take: 6,
        skip: 0,
        currentPage: 1,
        ...updatedState,
        selectedTab: tabName,
      })
    );
  };

  const handlePropertyTypeChange = (key) => {
    const selectedPropertyType = propertyType.find((type) => type.key === key)?.value;
  
    setStateData((prev) => {
      const isOfficeSpace = selectedPropertyType === 'OF';
      return {
        ...prev,
        propertyType: selectedPropertyType,
        ...(isOfficeSpace ? { bedroom: '', bathroom: '' } : {}),
      };
    });
  };
  

  const handleBedroomChange = (value) => {
    setStateData((prev) => ({ ...prev, bedroom: value }));
  };
  
  const handleBathroomChange = (value) => {
    setStateData((prev) => ({ ...prev, bathroom: value }));
  };

  const handleMinAreaChange = (valueOrEvent) => {
    const value = typeof valueOrEvent === 'string' ? valueOrEvent : valueOrEvent.target.value;
    setStateData((prev) => ({ ...prev, minSize: value }));
  };

  const handleMaxAreaChange = (valueOrEvent) => {
    const value = typeof valueOrEvent === 'string' ? valueOrEvent : valueOrEvent.target.value;
    setStateData((prev) => ({ ...prev, maxSize: value }));
  };

  const handleCommunityChange = (e) => {
    const value = e.target.value;
    setStateData((prev) => ({ ...prev, community: value }));

    const filtered = locationOption.filter((option) =>
      option.community.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredLocations(filtered);
    setShowSuggestions(true);
  };

  const commercialPropertyTypes = propertyType.filter((type) =>
    ["property_type.office_space", "property_type.show_room"].includes(type?.key)
  );
  
  const propertyTypesToShow = selectedTab === 'Commercial' ? commercialPropertyTypes : propertyType;

  useEffect(() => {
    if (location?.state) { 
      setStateData(location.state);  
      if (location.state.selectedTab) {
        setSelectedTab(location.state.selectedTab);
      }
    }
  }, [location?.state]);

  const getCleanQueryParams = () => {
    const queryParams = {};
    if (stateData?.propertyType) {
      queryParams.property_type = stateData.propertyType;
    }
  
    if (stateData?.bedroom === "studio") {
      queryParams.title_en = "studio";
    } else if (stateData?.bedroom) {
      queryParams.bedroom = stateData.bedroom;
    }
  
    if (stateData?.bathroom) {
      queryParams.bathroom = stateData.bathroom;
    }
  
    if (stateData?.minSize) {
      queryParams.minSize = stateData.minSize;
    }
    if (stateData?.maxSize) {
      queryParams.maxSize = stateData.maxSize;
    }
    if (stateData?.community) {
      queryParams.community = stateData.community;
    }
  
    if (selectedTab === "New Projects") {
      queryParams.completion_status = "off_plan_primary";
    }
  
    queryParams.property_purpose = propertyPurposeMapping[selectedTab];
    queryParams.skip = 0;
    queryParams.take = 6;
    queryParams.current = 1;
  
    return queryParams;
  };
  
  const searchHandler = () => {
    const cleanQueryParams = getCleanQueryParams();
  
    dispatch(setListingQueryParams({ ...cleanQueryParams, currentPage: 1 }));
    saveSearchToLocalStorage(cleanQueryParams);
  
    const tabParam = selectedTab === 'New Projects' ? 'new_projects' : propertyPurposeMapping[selectedTab];
    navigate(`/properties?tab=${tabParam}`, { state: { ...stateData, selectedTab } });
  };

  const resetPropertyType = () => setStateData((prev) => ({ ...prev, propertyType: '' }));
  const resetBedroom = () => setStateData((prev) => ({ ...prev, bedroom: '', bathroom: '' }));
  const resetArea = () => setStateData((prev) => ({ ...prev, minSize: '', maxSize: '' }));
  const StackComponent = useBreakpointValue({ base: VStack, md: HStack });

  return (
    <Box
      width={{ base: '90%', md: '80%', lg: '70%' }}  
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      mt={8}
      mx="auto"
      bg="transparent"
    >
      <Box
        bg="white"
        width="80%" 
        pl={{base: 2, md: 4}} 
        pr={{base: 2, md: 4}}
        mx= "0"
        boxShadow="lg"
        borderRadius="lg"
        position="relative"
        zIndex="10"
      >
        <Tabs variant="unstyled" bg="transparent" index={tabMapping[selectedTab]}>
          <TabList justifyContent="flex-start" width="100%" p={4} bg="white" flexWrap={{ base: 'wrap', md: 'nowrap' }}>
          <Tab
  onClick={() => handleTabChange('Rent')}
  _selected={{ borderBottom: '2px solid black', fontWeight: 'bold' }}
  _hover={{
    color: 'white',
    backgroundColor: '#ECC94B',
    borderRadius: 'md',
    transform: 'scale(1.05)',
    boxShadow: 'md',
  }}
  transition="all 0.05s ease"
  mr={{ base: 2, md: 4 }}
  fontSize={{ base: 'sm', md: 'md' }}
>
  <FormattedMessage id="searchBar.rent" defaultMessage="Rent" />
</Tab>

<Tab
  onClick={() => handleTabChange('Buy')}
  _selected={{ borderBottom: '2px solid black', fontWeight: 'bold' }}
  _hover={{
    color: 'white',
    backgroundColor: '#ECC94B',
    borderRadius: 'md',
    transform: 'scale(1.05)',
    boxShadow: 'md',
  }}
  transition="all 0.05s ease"
  mr={{ base: 2, md: 4 }}
  fontSize={{ base: 'sm', md: 'md' }}
>
  <FormattedMessage id="searchBar.tab_buy" defaultMessage="Buy" />
</Tab>

<Tab
  onClick={() => handleTabChange('New Projects')}
  _selected={{ borderBottom: '2px solid black', fontWeight: 'bold' }}
  _hover={{
    color: 'white',
    backgroundColor: '#ECC94B',
    borderRadius: 'md',
    transform: 'scale(1.05)',
    boxShadow: 'md',
  }}
  transition="all 0.05s ease"
  mr={{ base: 2, md: 4 }}
  fontSize={{ base: 'sm', md: 'md' }}
>
  <FormattedMessage id="searchBar.tab_new_projects" defaultMessage="New Projects" />
</Tab>

<Tab
  onClick={() => handleTabChange('Commercial')}
  _selected={{ borderBottom: '2px solid black', fontWeight: 'bold' }}
  _hover={{
    color: 'white',
    backgroundColor: '#ECC94B',
    borderRadius: 'md',
    transform: 'scale(1.05)',
    boxShadow: 'md',
  }}
  transition="all 0.05s ease"
  fontSize={{ base: 'sm', md: 'md' }}
>
  <FormattedMessage id="searchBar.tab_commercial" defaultMessage="Commercial" />
</Tab>
          </TabList>
        </Tabs>

        <Box height="1px" bg="gray.200" mt="-1px" />
        <StackComponent spacing={4} p={4} bg="white" borderRadius="lg" width="100%" alignItems="center" flexWrap={{ base: 'nowrap', md: 'wrap', lg: 'nowrap' }}>
        <InputGroup size="lg" bg="gray.100" borderRadius="lg" width="100%" position="relative"> 
          <InputLeftElement pointerEvents="none" children={<FaMapMarkerAlt color="gray.400" />} />
          <Input
            placeholder={intl.formatMessage({ id: "searchBar.placeholder_search", defaultMessage: "Search communities or building" })}
            variant="filled"
            borderRadius="lg"
            _placeholder={{ color: 'gray.500' }}
            value={stateData?.community
              ? intl.formatMessage({ id: stateData.community, defaultMessage: '' })  
              : ''}
            onChange={handleCommunityChange}  
          />
          {showSuggestions && stateData.community && (
            <Box bg="white" boxShadow="lg" borderRadius="md" mt="50px" zIndex={5} position="absolute" width="100%">
              <List spacing={2} maxHeight="350px" overflowY="auto">
                {filteredLocations.map((location, index) => (
                  <ListItem
                    key={index}
                    p={2}
                    _hover={{ backgroundColor: 'gray.100', cursor: 'pointer' }}
                    onClick={() => {
                      const translatedValue = intl.formatMessage({ id: location.community, defaultMessage: location.community });
                      setStateData((prev) => ({ ...prev, community: translatedValue })); 
                      setShowSuggestions(false);
                    }}
                  >
                    <FormattedMessage id={location.community} defaultMessage={location.community} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </InputGroup>

          <HStack spacing={4} width="100%" flexWrap="nowrap" direction={{base:"row", md: "row", lg: "row"}}>
          {(selectedTab === 'Rent' || selectedTab === 'Buy' || selectedTab === 'New Projects' || selectedTab === 'Commercial') && (
           <Menu>
           <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="lg" bg="gray.100">
           {stateData?.propertyType
            ? intl.formatMessage({
                id: propertyTypesToShow.find((type) => type.value === stateData.propertyType)?.key || "searchBar.property_type",
                defaultMessage: "Property Type",
              })
               : <FormattedMessage id="searchBar.property_type" defaultMessage="Property Type" />
             }
           </MenuButton>
           <MenuList>
             {propertyTypesToShow?.map((type) => ( 
               <MenuItem key={type?.value} onClick={() => handlePropertyTypeChange(type?.key)}>
                 <FormattedMessage id={type?.key} defaultMessage={type?.key} />
               </MenuItem>
             ))}
             <MenuItem onClick={resetPropertyType}><FormattedMessage id='reset' defaultMessage="Reset" /></MenuItem>
           </MenuList>
         </Menu>
          )}
          {(selectedTab === 'Rent' || selectedTab === 'Buy' || selectedTab === "New Projects") && (
            stateData?.propertyType === "OF" ? (
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="lg" bg="gray.100">
                  {stateData?.minSize && stateData?.maxSize
                    ? `${stateData.minSize} - ${stateData.maxSize} ${intl.formatMessage({ id: "searchBar.sqft", defaultMessage: "sqft" })}`
                    : <FormattedMessage id="searchBar.area" defaultMessage="Area (sqft)" />}
                </MenuButton>
                <MenuList>
                  <Box p={4} width="400px">
                    <Flex alignItems="center" justifyContent="space-between" gap={4}>
                      <InputGroup width="30%"> 
                        <Input
                          placeholder={intl.formatMessage({ id: "searchBar.min_area", defaultMessage: "Min Area" })}
                          value={stateData?.minSize} 
                          onChange={handleMinAreaChange}
                          onFocus={() => setIsMinMenuOpen(true)}  
                          onBlur={() => setTimeout(() => setIsMinMenuOpen(false), 200)}  
                        />
                        {isMinMenuOpen && (
                          <Box bg="white" boxShadow="lg" borderRadius="md" mt="40px" zIndex={10} position="absolute" width="100%">
                            <List spacing={2}>
                              {areaOptions?.map((option) => (
                                <ListItem
                                  key={option}
                                  p={2}
                                  _hover={{ backgroundColor: 'gray.100', cursor: 'pointer' }}
                                  onClick={() => {
                                    handleMinAreaChange(option);
                                    setIsMinMenuOpen(false);  
                                  }}
                                >
                                  {option} <FormattedMessage id="searchBar.sqft" defaultMessage="sqft" />
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        )}
                      </InputGroup>
                      <InputGroup width="30%"> 
                        <Input
                          placeholder={intl.formatMessage({ id: "searchBar.max_area", defaultMessage: "Max Area" })}
                          value={stateData?.maxSize} 
                          onChange={handleMaxAreaChange}
                          onFocus={() => setIsMaxMenuOpen(true)} 
                          onBlur={() => setTimeout(() => setIsMaxMenuOpen(false), 200)}  
                        />
                        {isMaxMenuOpen && (
                          <Box bg="white" boxShadow="lg" borderRadius="md" mt="40px" zIndex={5} position="absolute" width="100%">
                            <List spacing={2}>
                              {areaOptions?.map((option) => (
                                <ListItem
                                  key={option}
                                  p={2}
                                  _hover={{ backgroundColor: 'gray.100', cursor: 'pointer' }}
                                  onClick={() => {
                                    handleMaxAreaChange(option);
                                    setIsMaxMenuOpen(false);  
                                  }}
                                >
                                  {option} <FormattedMessage id="searchBar.sqft" defaultMessage="sqft" />
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        )}
                      </InputGroup>

                      <Button mt={0} zIndex={1} colorScheme="red" onClick={resetArea}>
                        <FormattedMessage id='reset' />
                      </Button>

                    </Flex>
                  </Box>
                </MenuList>
              </Menu>
            ) : (selectedTab !== 'New Projects') && (
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="lg" bg="gray.100">
                { stateData?.bedroom?.toLowerCase() === "studio" || stateData?.bedroom === "استوديو" ? (
                    <>
                    <FormattedMessage id="searchBar.studio" defaultMessage="Studio" />
                    {stateData?.bathroom && (
                      <>
                        ,{" "} {stateData.bathroom} <FormattedMessage id="searchBar.baths" defaultMessage="Baths" />
                      </>
                    )}
                  </>
                  ) : stateData?.bedroom && stateData?.bathroom ? (
                    <>
                      {stateData.bedroom} <FormattedMessage id="searchBar.beds" defaultMessage="Beds" />,{" "}
                      {stateData.bathroom} <FormattedMessage id="searchBar.baths" defaultMessage="Baths" />
                    </>
                  ) : stateData?.bedroom ? (
                    <>
                      {stateData.bedroom} <FormattedMessage id="searchBar.beds" defaultMessage="Beds" />
                    </>
                  ) : stateData?.bathroom ? (
                    <>
                      {stateData.bathroom} <FormattedMessage id="searchBar.baths" defaultMessage="Baths" />
                    </>
                  ) : (
                    <FormattedMessage id="searchBar.beds_and_baths" defaultMessage="Beds & Baths" />
                  )
                }
                </MenuButton>
                <MenuList minWidth="350px">
                  <Box p={4}>
                    <Text fontWeight="bold" mb={2}><FormattedMessage id="searchBar.bedrooms" defaultMessage="Bedrooms" /></Text>
                    <SimpleGrid columns={{ base: 2, md: 4}} spacing={2} mb={4}>
                      <MenuItem onClick={() => handleBedroomChange('studio')}><FormattedMessage id="searchBar.studio" defaultMessage="Studio" /></MenuItem>
                      <MenuItem onClick={() => handleBedroomChange('1')}>1</MenuItem>
                      <MenuItem onClick={() => handleBedroomChange('2')}>2</MenuItem>
                      <MenuItem onClick={() => handleBedroomChange('3')}>3</MenuItem>
                      <MenuItem onClick={() => handleBedroomChange('4')}>4</MenuItem>
                      <MenuItem onClick={() => handleBedroomChange('5')}>5</MenuItem>
                      <MenuItem onClick={() => handleBedroomChange('6')}>6</MenuItem>
                      <MenuItem onClick={() => handleBedroomChange('7+')}>7+</MenuItem>
                    </SimpleGrid>

                    <Text fontWeight="bold" mb={2}><FormattedMessage id="searchBar.bathrooms" defaultMessage="Bathrooms" /></Text>
                    <SimpleGrid columns={{ base: 2, md: 4}} spacing={2}>
                      <MenuItem onClick={() => handleBathroomChange('1')}>1</MenuItem>
                      <MenuItem onClick={() => handleBathroomChange('2')}>2</MenuItem>
                      <MenuItem onClick={() => handleBathroomChange('3')}>3</MenuItem>
                      <MenuItem onClick={() => handleBathroomChange('4')}>4</MenuItem>
                      <MenuItem onClick={() => handleBathroomChange('5')}>5</MenuItem>
                      <MenuItem onClick={() => handleBathroomChange('6')}>6</MenuItem>
                      <MenuItem onClick={() => handleBathroomChange('7+')}>7+</MenuItem>
                    </SimpleGrid>
                    <MenuItem onClick={resetBedroom}><FormattedMessage id='reset' /></MenuItem>
                  </Box>
                </MenuList>
              </Menu>
            )
          )}

          {selectedTab === 'New Projects' && stateData?.propertyType !== "OF" && (
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="lg" bg="gray.100">
                {stateData?.bedroom === "Studio" ? (
                     <FormattedMessage id="searchBar.studio" defaultMessage="Studio" /> 
                  ) : stateData?.bedroom ? (
                    <>
                      {stateData.bedroom} <FormattedMessage id="searchBar.beds" defaultMessage="Beds" />
                    </>
                  ) : (
                   <FormattedMessage id="searchBar.bedrooms" defaultMessage="Bedrooms" />
                  )
                } 
              </MenuButton>
              <MenuList minWidth="250px">
                <SimpleGrid columns={{ base: 2, md: 4}} row={1} spacing={2} p={2}>
                  <MenuItem onClick={() => handleBedroomChange('Studio')}><FormattedMessage id="searchBar.studio" defaultMessage="Studio" /></MenuItem>
                  <MenuItem onClick={() => handleBedroomChange('1')}>1</MenuItem>
                  <MenuItem onClick={() => handleBedroomChange('2')}>2</MenuItem>
                  <MenuItem onClick={() => handleBedroomChange('3')}>3</MenuItem>
                  <MenuItem onClick={() => handleBedroomChange('4')}>4</MenuItem>
                  <MenuItem onClick={() => handleBedroomChange('5')}>5</MenuItem>
                  <MenuItem onClick={() => handleBedroomChange('6')}>6</MenuItem>
                  <MenuItem onClick={() => handleBedroomChange('7+')}>7+</MenuItem>
                </SimpleGrid>
                <MenuItem onClick={resetBedroom}><FormattedMessage id='reset' /></MenuItem>
              </MenuList>
            </Menu>
          )}

          {selectedTab === 'Commercial' && (
            <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="lg" bg="gray.100">
              {stateData?.minSize && stateData?.maxSize
                ? `${stateData.minSize} - ${stateData.maxSize} ${intl.formatMessage({ id: "searchBar.sqft", defaultMessage: "sqft" })}`
                : <FormattedMessage id="searchBar.area" defaultMessage="Area (sqft)" />}
            </MenuButton>
            <MenuList>
              <Box p={4} width="400px">
                <Flex alignItems="center" justifyContent="space-between" gap={4}>
                  <InputGroup width="30%"> 
                    <Input
                      placeholder={intl.formatMessage({ id: "searchBar.min_area", defaultMessage: "Min Area" })}
                      value={stateData?.minSize} 
                      onChange={handleMinAreaChange}
                      onFocus={() => setIsMinMenuOpen(true)}  
                      onBlur={() => setTimeout(() => setIsMinMenuOpen(false), 200)}  
                    />
                    {isMinMenuOpen && (
                      <Box bg="white" boxShadow="lg" borderRadius="md" mt="40px" zIndex={10} position="absolute" width="100%">
                        <List spacing={2}>
                          {areaOptions?.map((option) => (
                            <ListItem
                              key={option}
                              p={2}
                              _hover={{ backgroundColor: 'gray.100', cursor: 'pointer' }}
                              onClick={() => {
                                handleMinAreaChange(option);
                                setIsMinMenuOpen(false);  
                              }}
                            >
                              {option} <FormattedMessage id="searchBar.sqft" defaultMessage="sqft" />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    )}
                  </InputGroup>
                  <InputGroup width="30%"> 
                    <Input
                      placeholder={intl.formatMessage({ id: "searchBar.max_area", defaultMessage: "Max Area" })}
                      value={stateData?.maxSize} 
                      onChange={handleMaxAreaChange}
                      onFocus={() => setIsMaxMenuOpen(true)} 
                      onBlur={() => setTimeout(() => setIsMaxMenuOpen(false), 200)}  
                    />
                    {isMaxMenuOpen && (
                      <Box bg="white" boxShadow="lg" borderRadius="md" mt="40px" zIndex={5} position="absolute" width="100%">
                        <List spacing={2}>
                          {areaOptions?.map((option) => (
                            <ListItem
                              key={option}
                              p={2}
                              _hover={{ backgroundColor: 'gray.100', cursor: 'pointer' }}
                              onClick={() => {
                                handleMaxAreaChange(option);
                                setIsMaxMenuOpen(false);  
                              }}
                            >
                              {option} <FormattedMessage id="searchBar.sqft" defaultMessage="sqft" />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    )}
                  </InputGroup>
          
                  <Button mt={0} zIndex={1} colorScheme="red" onClick={resetArea}>
                    <FormattedMessage id='reset' />
                  </Button>
          
                </Flex>
              </Box>
            </MenuList>
          </Menu>          
          )}
          <IconButton
            size="lg"
            colorScheme="yellow"
            icon={<SearchIcon />}
            borderRadius="lg"
            aria-label={<FormattedMessage id="searchBar.search_button" defaultMessage="Search button" />}
            onClick={searchHandler}
          />
        </HStack>
        </StackComponent>
      </Box>
    </Box>
  );
};

export default SearchBar;
