export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

export const createIntervals = (minVal, maxVal) => {
  const intervalSize = (maxVal - minVal) / 10;
  const intervals = [];
  for (let i = 0; i < 10; i++) {
    const start = minVal + i * intervalSize;
    const end = start + intervalSize;
    intervals.push({
      value: `${start}-${end}`,
      label: `AED ${start}  to AED ${end}`,
    });
  }
  return intervals;
};

export const getRandomImages = (images) => {
  const randomImages = [];
  for (let i = 0; i < 10; i++) {
    var randomIndex = Math.floor(Math.random() * images.length);
    randomImages.push(images[randomIndex]);
    images.splice(randomIndex, 1);
  }
  return randomImages;
};

export const getApiQueryParams = (
  startIndex,
  type,
  maxPrice,
  minPrice,
  locality
) => {
  let str = "";
  if (startIndex >= 0) {
    str = str + `&skip=${startIndex}`;
  }
  if (locality?.length > 0) {
    str = str + `&community=${locality}`;
  }
  if (type?.length > 0) {
    str = str + `&property_type=${type}`;
  }
  if (maxPrice > 0) {
    str = str + `&maxPrice=${maxPrice}`;
  }
  if (minPrice >= 0) {
    str = str + `&minPrice=${minPrice}`;
  }
  return str;
};

export const HandleArabicClick = () => {
  const lang = localStorage.getItem("lang");
  if (!lang) {
    localStorage.setItem("lang", "true");
    window.location.href = "/";
  }
};

export const HandleEnglishClick = () => {
  const lang = localStorage.getItem("lang");
  if (lang) {
    localStorage.removeItem("lang");
    window.location.href = "/";
  }
};

export const filterQueryParams = ({ params, excludedParams }) =>
  Object.fromEntries(
    Object.entries(params).filter(
      ([key, value]) =>
        value !== "" &&
        value !== null &&
        value !== undefined &&
        !Array.isArray(value) &&
        !excludedParams?.includes(key)
    )
  );

export const appendQueryParam = (url, params = {}) => {
  const encodeValue = (value) => {
    return value.join(",");
  };

  for (const [key, value] of Object.entries(params)) {
    if (value !== null && value !== undefined) {
      const encodedKey = key.includes("[]") ? key.replace("[]", "") : key;
      let encodedValue = null;
      if (Array.isArray(value) && value.length > 0) {
        encodedValue = encodeValue(value);
      } else if (Array.isArray(value) && value.length === 0) {
        continue;
      } else {
        encodedValue = encodeURIComponent(value);
      }

      url += `${url.includes("?") ? "&" : "?"}${encodedKey}=${encodedValue}`;
    }
  }

  return url;
};

export const calculateDaysAgo = (createdAt) => {
  const createdDate = new Date(createdAt);
  const currentDate = new Date();
  const differenceInTime = currentDate - createdDate;
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24)); 
  return differenceInDays === 0 ? "Today" : `${differenceInDays} days ago`;
};

export const saveSearchToLocalStorage = (searchParams) => {
  const auth = JSON.parse(localStorage.getItem("userData"));
  const userId = auth?.id;

  if (userId) {
    const userSearches = JSON.parse(localStorage.getItem(`savedSearches_${userId}`)) || [];
    userSearches.push(searchParams);

    const limitedSearches = userSearches.slice(-3);
    localStorage.setItem(`savedSearches_${userId}`, JSON.stringify(limitedSearches));
  }
};
