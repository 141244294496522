import { Button } from "antd";
import React from "react";
import "../../styles/home.css";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
const HomeContactUs = () => {
  return (
    <div className="home-contact-us-container">
      <div className="home-contact-us-text">
        <p className="home-contact-us-heading">
          <FormattedMessage id="need_help" defaultMessage="Need Help? Talk To Our Expert." />
        </p>
        <p className="home-contact-us-paragraph">
          <FormattedMessage id="help_text" defaultMessage="Talk to our experts or Browse through more properties." />
        </p>
      </div>
      <div className="home-contact-us-buttons">
        <Link to="/contact-us">
          <Button className="contact-us-button">
            <FormattedMessage id="contact_us" defaultMessage="Contact Us" />
          </Button>
        </Link>
      </div>
    </div>
  );
};
export default HomeContactUs;