import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  propertyList: [],
  listingQueryParams: {
    skip: 0,
    take: 6,
    currentPage: 1,
    title_en: null,
    property_type: null,
    minPrice: 0,
    maxPrice: 20000000,
    community: null,
    minArea: 0,
    maxArea: 200000,
    bathroom: 0,
    bedroom: 0,
    property_purpose: null,
    completion_status: null,
  },
};

const propertySlice = createSlice({
  name: "PropertyList",
  initialState,
  reducers: {
    setPropertyList: (state, action) => {
      state.propertyList = action.payload;
    },
    setListingQueryParams: (state, action) => {
      state.listingQueryParams = action.payload;
    },
    resetListingQueryParams: (state) => {
      state.listingQueryParams = initialState?.listingQueryParams;
    },
  },
});

export const {
  setPropertyList,
  setListingQueryParams,
  resetListingQueryParams,
} = propertySlice.actions;
export default propertySlice.reducer;
