import React from 'react';
import { Box, Image, Text, Heading } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

export const FeatureCard = ({ image, title, details }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      p="6"
      boxShadow="none"  
      background="transparent" 
    >
      <Box flexShrink={0} mr="4">
        <Image
          src={image}
          alt={title}
          boxSize="50px"
        />
      </Box>
      <Box>
        <Heading as="h3" size="md" mb="2">
          <FormattedMessage id={title} />
        </Heading>
        <Text color="gray.500">
          <FormattedMessage id={details} />
        </Text>
      </Box>
    </Box>
  );
};

export default FeatureCard;
