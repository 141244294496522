import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const PropertyTypes = ({ selectedType, onSelectType }) => {
  return (
    <Flex gap={4}>
      <Button
        bg={selectedType === 'sale' ? 'black' : 'white'}
        color={selectedType === 'sale' ? 'white' : 'black'}
        border="1px solid black"
        _hover={{ bg: selectedType === 'sale' ? 'gray.700' : 'gray.100' }}
        _active={{ bg: 'black', color: 'white' }}
        onClick={() => onSelectType('sale')}
      >
        <FormattedMessage id="searchBar.tab_sale" defaultMessage="For Sale" />
      </Button>

      <Button
        bg={selectedType === 'rent' ? 'black' : 'white'}
        color={selectedType === 'rent' ? 'white' : 'black'}
        border="1px solid black"
        _hover={{ bg: selectedType === 'rent' ? 'gray.700' : 'gray.100' }}
        _active={{ bg: 'black', color: 'white' }}
        onClick={() => onSelectType('rent')}
      >
        <FormattedMessage id="searchBar.tab_rent" defaultMessage="For Rent" />
      </Button>
    </Flex>
  );
};

export default PropertyTypes;
