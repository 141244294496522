import React, { useState, useRef } from "react";
import { Form, Input, InputNumber, Select, Button, Row, Col, Rate, message } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { authAxios } from "../../../utils/axiosMiddleware";
import { base_url } from "../../../constants/constant";
import { timeFrame } from "../../../utils/constant";
import { useSelector } from "react-redux";
import SuccessModal from "../SuccessModal";  
import { injectIntl } from "react-intl";

const ContactForm = ({ property, agent, disable, setDisable, auth, intl }) => {
  const { profileData } = useSelector((state) => state?.user);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const onFinish = async (values) => {
    if (!auth) {
      message.error(intl.formatMessage({ id: "contactForm.loginError", defaultMessage: "Please log in/Register first to submit a contact request." }));
      return;
    }
    setDisable(true);
    try {
      await authAxios.post(`${base_url}/leads`, {
        firstName: values?.name.split(" ")[0],
        lastName: values?.name.split(" ")[1] || "",
        email: values?.email,
        phone: values?.phone,
        leadId: Number(values?.leadId),
        MinBudget: String(values?.budget?.min),
        MaxBudget: String(values?.budget?.max),
        financing: values?.financing,
        source: property?.source,
        passport: values?.passport,
        passportExpiry: values?.passportExpiry ? values?.passportExpiry.toISOString() : null,
        status: property?.status,
        property_type: property?.property_type,
        Timeframe: values?.time_frame,
        shortlisted: property?.likesCount,
        propertyId: property?.id,
        userId: auth?.id,
        quality: values?.quality,
        notes: values?.notes,
        assignedUserId: agent?.id,
        assignedUserName: agent?.name || "Unknown",
      });
      setIsModalVisible(true);
      formRef.current.resetFields();
      setDisable(false);
    } catch (err) {
      message.error(err?.response?.data?.message);
      setDisable(false);
    }
  };

  return (
    <>
      <Form
        initialValues={{
          name: profileData?.profileName || "",
          email: profileData?.email || "",
        }}
        onFinish={onFinish}
        form={form}
        ref={formRef}
        style={{ marginTop: "10px" }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: intl.formatMessage({ id: "contactForm.nameError", defaultMessage: "Please enter the name" }) }]}
            >
              <Input placeholder={intl.formatMessage({ id: "contactForm.namePlaceholder", defaultMessage: "Enter Name" })} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              rules={[{ required: true, type: "email", message: intl.formatMessage({ id: "contactForm.emailError", defaultMessage: "Please enter a valid email" }) }]}
            >
              <Input placeholder={intl.formatMessage({ id: "contactForm.emailPlaceholder", defaultMessage: "Enter Email" })} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: intl.formatMessage({ id: "contactForm.phoneError", defaultMessage: "Please enter Phone Number" }) }]}
            >
              <PhoneInput
                inputProps={{ name: "phone", required: true }}
                countryCodeEditable={false}
                country={"us"}
                prefix="+"
                inputStyle={{ width: "100%", height: "55px", backgroundColor: "white", marginTop: "8px" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Input.Group compact>
                <Form.Item
                  name={['budget', 'min']}
                  noStyle
                  rules={[{ required: true, message: intl.formatMessage({ id: "contactForm.minBudgetError", defaultMessage: "Please enter the minimum budget" }) }]}
                >
                  <InputNumber placeholder={intl.formatMessage({ id: "contactForm.minBudgetPlaceholder", defaultMessage: "Min Budget" })} style={{ height: "48px", width: "50%" }} />
                </Form.Item>

                <Form.Item
                  name={['budget', 'max']}
                  noStyle
                  rules={[{ required: true, message: intl.formatMessage({ id: "contactForm.maxBudgetError", defaultMessage: "Please enter the maximum budget" }) }]}
                >
                  <InputNumber placeholder={intl.formatMessage({ id: "contactForm.maxBudgetPlaceholder", defaultMessage: "Max Budget" })} style={{ height: "48px", width: "50%" }} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name="financing"
              rules={[{ required: true, message: intl.formatMessage({ id: "contactForm.financingError", defaultMessage: "Please select the financing option" }) }]}
            >
              <Select placeholder={intl.formatMessage({ id: "contactForm.financingPlaceholder", defaultMessage: "Select Financing" })} getPopupContainer={(triggerNode) => triggerNode.parentNode}>
                <Select.Option value="Cash">Cash</Select.Option>
                <Select.Option value="Mortgage">Mortgage</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="time_frame"
              rules={[{ required: true, message: intl.formatMessage({ id: "contactForm.timeFrameError", defaultMessage: "Please enter the time frame" }) }]}
            >
              <Select placeholder={intl.formatMessage({ id: "contactForm.timeFramePlaceholder", defaultMessage: "Time Frame" })} className="select" getPopupContainer={(triggerNode) => triggerNode.parentNode}>
                {timeFrame?.map((option) => (
                  <Select.Option key={option?.key} value={option?.value}>
                    {option?.key}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item name="quality" label={intl.formatMessage({ id: "contactForm.qualityLabel", defaultMessage: "Rate the Quality" })} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Rate count={5} style={{ color: "#fadb14" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item name="notes">
              <Input.TextArea placeholder={intl.formatMessage({ id: "contactForm.notesPlaceholder", defaultMessage: "Enter Notes" })} autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button
            className="contact-button"
            htmlType="submit"
            disabled={disable}
          >
            {intl.formatMessage({ id: "contactForm.submitButton", defaultMessage: "Contact" })}
          </Button>
        </Form.Item>
      </Form>

      <SuccessModal
        isModalVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      />
    </>
  );
};

export default injectIntl(ContactForm);
