import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const PropertyTypeSection = ({ selectedType, onSelectType }) => {
  return (
    <Flex gap={4}>
      <Button
        bg={selectedType === 'OF' ? 'black' : 'white'}
        color={selectedType === 'OF' ? 'white' : 'black'}
        border="1px solid black"
        _hover={{ bg: selectedType === 'OF' ? 'gray.700' : 'gray.100' }}
        _active={{ bg: 'black', color: 'white' }}
        onClick={() => onSelectType('OF')}
      >
        <FormattedMessage id="property_type.office_space" defaultMessage="Office Space" />
      </Button>

      <Button
        bg={selectedType === 'VH' ? 'black' : 'white'}
        color={selectedType === 'VH' ? 'white' : 'black'}
        border="1px solid black"
        _hover={{ bg: selectedType === 'VH' ? 'gray.700' : 'gray.100' }}
        _active={{ bg: 'black', color: 'white' }}
        onClick={() => onSelectType('VH')}
      >
        <FormattedMessage id="property_type.villa" defaultMessage="Villas" />
      </Button>

      <Button
        bg={selectedType === 'AP' ? 'black' : 'white'}
        color={selectedType === 'AP' ? 'white' : 'black'}
        border="1px solid black"
        _hover={{ bg: selectedType === 'AP' ? 'gray.700' : 'gray.100' }}
        _active={{ bg: 'black', color: 'white' }}
        onClick={() => onSelectType('AP')}
      >
        <FormattedMessage id="property_type.apartment" defaultMessage="Apartments" />
      </Button>

      <Button
        bg={selectedType === 'DX' ? 'black' : 'white'}
        color={selectedType === 'DX' ? 'white' : 'black'}
        border="1px solid black"
        _hover={{ bg: selectedType === 'DX' ? 'gray.700' : 'gray.100' }}
        _active={{ bg: 'black', color: 'white' }}
        onClick={() => onSelectType('DX')}
      >
        <FormattedMessage id="property_type.duplex" defaultMessage="Duplex" />
      </Button>

      <Button
        bg={selectedType === 'TH' ? 'black' : 'white'}
        color={selectedType === 'TH' ? 'white' : 'black'}
        border="1px solid black"
        _hover={{ bg: selectedType === 'TH' ? 'gray.700' : 'gray.100' }}
        _active={{ bg: 'black', color: 'white' }}
        onClick={() => onSelectType('TH')}
      >
        <FormattedMessage id="property_type.townhouse" defaultMessage="Townhouses" />
      </Button>

      <Button
        bg={selectedType === 'PH' ? 'black' : 'white'}
        color={selectedType === 'PH' ? 'white' : 'black'}
        border="1px solid black"
        _hover={{ bg: selectedType === 'PH' ? 'gray.700' : 'gray.100' }}
        _active={{ bg: 'black', color: 'white' }}
        onClick={() => onSelectType('PH')}
      >
        <FormattedMessage id="property_type.penthouse" defaultMessage="Penthouses" />
      </Button>
    </Flex>
  );
};

export default PropertyTypeSection;
