import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

const Header = () => {
  return (
    <Box>
      <Heading as="h2" size="xl" fontWeight="bold" color="black" mb={2}>
        <FormattedMessage id="latest_properties" />
      </Heading>
      <Text fontSize="lg" color="gray.500">
      <FormattedMessage id="latest_description" />
      </Text>
    </Box>
  );
};

export default Header;
