import React, { useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Form, Input, Row, Col, Card, message, Spin } from "antd";
import { Button } from "@chakra-ui/react";
import { LockOutlined } from "@ant-design/icons";
import { authAxios } from "../../utils/axiosMiddleware";
import { EditUser } from "../../api/auth";
import { FormattedMessage, useIntl } from "react-intl";

export const UserProfile = () => {
  const auth = JSON.parse(localStorage.getItem("userData"));
  const intl = useIntl();
  const [form] = Form.useForm();

  const {
    data: userProfile,
    isLoading: profileLoading,
    isSuccess,
    refetch,
  } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["UserProfile"],
    queryFn: () =>
      authAxios.get(`users/${auth?.id}`, {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }),
    select: (res) => res?.data,
  });

  useEffect(() => {
    if (isSuccess && userProfile) {
      form.setFieldsValue({
        name: userProfile?.name,
        email: userProfile?.email,
      });
    }
  }, [isSuccess, userProfile, form]);

  const mutation = useMutation({
    mutationFn: (updatedData) => EditUser(auth?.id, updatedData),
    onSuccess: (response) => {
      message.success(intl.formatMessage({ id: "profile.updated", defaultMessage: "Profile updated successfully!" }));
      const updatedUserData = { ...auth, ...response.data };
      localStorage.setItem("userData", JSON.stringify(updatedUserData));
      refetch();
    },
    onError: () => {
      message.error(intl.formatMessage({ id: "profile.updateFailed", defaultMessage: "Failed to update profile" }));
    },
  });

  const onFinish = (values) => {
    const updatedData = { ...values };
    if (!values.password) {
      delete updatedData.password;
      delete updatedData.confirm;
    }
    mutation.mutate(updatedData);
  };

  if (profileLoading) {
    return <Spin />;
  }

  return (
    <div
      style={{
        paddingTop: "40px",
        background: "transparent",
        minHeight: "65vh",
      }}
    >
      <Row justify="center">
        <Col span={12}>
          <Card
            title={<FormattedMessage id="profile.info" defaultMessage="Information" />}
            bordered={false}
            style={{
              marginLeft:"-70px",
              marginRight:"40px",
              backgroundColor: "white",
            }}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              initialValues={{
                name: userProfile?.name,
                email: userProfile?.email,
              }}
            >
              <Form.Item
                name="name"
                label={<FormattedMessage id="profile.fullName" defaultMessage="Full Name" />}
                rules={[
                  { required: true, message: <FormattedMessage id="profile.enterFullName" defaultMessage="Please enter your full name" /> },
                ]}
                style={{ paddingTop: "20px" }}
              >
                <Input
                  placeholder={intl.formatMessage({id:"profile.placeholder.fullName", defaultMessage:"Enter your full name"})}
                  style={{ padding: "10px" }}
                />
              </Form.Item>

              <Form.Item
                name="email"
                label={<FormattedMessage id="profile.email" defaultMessage="Email" />}
                rules={[{ required: true, message: <FormattedMessage id="profile.enterEmail" defaultMessage="Please enter your email" /> }]}
              >
                <Input
                  disabled
                  placeholder={intl.formatMessage({id:"profile.placeholder.email", defaultMessage:"Enter your email"})}
                  style={{ padding: "10px" }}
                />
              </Form.Item>

              <Form.Item
                name="password"
                label={<FormattedMessage id="profile.password" defaultMessage="Password" />}
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="profile.enterPassword" defaultMessage="Please enter password" />,
                  },
                  {
                    pattern: /[A-Z]/,
                    message: <FormattedMessage id="profile.password.uppercase" defaultMessage="- Must have one uppercase letter (A-Z)." />,
                  },
                  {
                    pattern: /[a-z]/,
                    message: <FormattedMessage id="profile.password.lowercase" defaultMessage="- Must have one lowercase letter (a-z)." />,
                  },
                  {
                    pattern: /\d/,
                    message: <FormattedMessage id="profile.password.number" defaultMessage="- Must have one number (0-9)." />,
                  },
                  {
                    pattern: /[!@#$%^&*]/,
                    message: <FormattedMessage id="profile.password.specialChar" defaultMessage="- Must have one special character (!@#$%^&*)." />,
                  },
                  {
                    min: 8,
                    message: <FormattedMessage id="profile.password.length" defaultMessage="- Must be at least 8 characters long." />,
                  },
                ]}
              >
                <Input.Password
                  placeholder={intl.formatMessage({
                    id: "profile.placeholder.password",
                    defaultMessage: "Enter your new password",
                  })}
                  prefix={<LockOutlined />}
                  style={{ padding: "4px" }}
                />
              </Form.Item>

              <Form.Item
                name="confirm"
                label={<FormattedMessage id="profile.confirmPassword" defaultMessage="Confirm Password" />}
                dependencies={["password"]}
                hasFeedback
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (getFieldValue("password") && !value) {
                        return Promise.reject(
                          new Error(intl.formatMessage({ id: "profile.confirmPassword.enter" }))
                        );
                      }

                      if (value && getFieldValue("password") !== value) {
                        return Promise.reject(
                          new Error(intl.formatMessage({ id: "profile.confirmPassword.match" }))
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password
                   placeholder={intl.formatMessage({
                    id: "profile.placeholder.confirmPassword",
                    defaultMessage: "Confirm Password",
                  })}
                  prefix={<LockOutlined />}
                  style={{ padding: "4px" }}
                />
              </Form.Item>

              <Form.Item>
              <Button
                type="submit"
                isLoading={mutation.isLoading}
                width="100%"
                fontWeight="bold"
                fontSize="15px"
                color="white"
                bgGradient="linear(to-r, yellow.400, yellow.500, yellow.600)"
                _hover={{
                  bgGradient: "linear(to-r, yellow.500, yellow.600, yellow.700)",
                  boxShadow: "md",
                }}
                _active={{
                  bgGradient: "linear(to-r, yellow.600, yellow.700, yellow.800)",
                  boxShadow: "inner",
                }}
              >
                <FormattedMessage id="profile.save" defaultMessage="Save" />
              </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
