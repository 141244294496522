import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Grid,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";
import { GiWoodCabin } from 'react-icons/gi';
import {
  FaUmbrellaBeach,
  FaCouch,
  FaDumbbell,
  FaSnowflake,
  FaParking,
  FaBath,
  FaSwimmingPool,
  FaTools,
  FaHouseUser,
  FaTree,
  FaConciergeBell,
  FaDoorOpen,
  FaWater,
  FaFire,
  FaClipboardCheck,
  FaCreditCard,
  FaCity,
  FaStreetView,
  FaUserFriends,
  FaUtensils,
  FaSubway,
  FaMosque,
  FaShoppingCart,
  FaLeaf,
  FaSpa,
  FaPenFancy,
  FaBuilding,
  FaChess,
  FaCar,
  FaEye,
  FaLocationArrow,
  FaBed,
  FaPlane,
  FaHospital,
  FaCompass,
  FaDog,
  FaHotTub,
  FaBox,
  FaBrush,
  FaCoffee,
  FaBlender,
  FaLock,
} from "react-icons/fa";
import { TbCurrencyDirham } from "react-icons/tb";
import { FormattedMessage } from "react-intl";

const amenitiesMapping = {
    BA: { icon: FaUmbrellaBeach, label: <FormattedMessage id="amenity.balcony" defaultMessage="Balcony" />},
    BW: { icon: FaCouch, label: <FormattedMessage id="amenity.builtInWardrobes" defaultMessage="Built-in Wardrobes" />},
    AC: { icon: FaSnowflake, label: <FormattedMessage id="amenity.centralAC" defaultMessage="Central A/C" />},
    PJ: { icon: FaSwimmingPool, label: <FormattedMessage id="amenity.privateJacuzzi" defaultMessage="Private Jacuzzi" />},
    MR: { icon: FaUserFriends, label: <FormattedMessage id="amenity.maidRoom" defaultMessage="Maid Room" /> },
    MB: { icon: FaBath, label: <FormattedMessage id="amenity.marbleFloors" defaultMessage="Marble Floors" />},
    SP: { icon: FaSwimmingPool, label: <FormattedMessage id="amenity.swimmingPool" defaultMessage="Shared Swimming Pool" />},
    SE: { icon: FaLock, label: <FormattedMessage id="amenity.security" defaultMessage="Security" />},
    MT: { icon: FaTools, label: <FormattedMessage id="amenity.maintenance" defaultMessage="Maintenance" />},
    SM: { icon: FaHouseUser, label: <FormattedMessage id="amenity.nearSupermarket" defaultMessage="Near Supermarket" />},
    ML: { icon: FaCity, label: <FormattedMessage id="amenity.nearMall" defaultMessage="Near Mall" />, ar: "بالقرب من المول" },
    CS: { icon: FaConciergeBell, label: <FormattedMessage id="amenity.conciergeService" defaultMessage="Concierge Service" />},
    CP: { icon: FaParking, label: <FormattedMessage id="amenity.coveredParking" defaultMessage="Covered Parking" />},
    SY: { icon: FaDumbbell, label: <FormattedMessage id="amenity.sharedGym" defaultMessage="Shared Gym" />},
    WC: { icon: FaDoorOpen, label: <FormattedMessage id="amenity.walkInCloset" defaultMessage="Walk-in Closet" />},
    VW: { icon: FaWater, label: <FormattedMessage id="amenity.viewOfWater" defaultMessage="Sea/Water View" />},
    BP: { icon: FaBuilding, label: <FormattedMessage id="amenity.lobbyInBuilding" defaultMessage="Lobby in Building" />},
    BB: { icon: FaFire, label: <FormattedMessage id="amenity.bbqArea" defaultMessage="BBQ Area" />},
    PA: { icon: FaDog, label: <FormattedMessage id="amenity.petsAllowed" defaultMessage="Pets Allowed" />},
    CW: { icon: FaCity, label: <FormattedMessage id="amenity.cityView" defaultMessage="City View" />},
    CV: { icon: FaStreetView, label: <FormattedMessage id="amenity.communityView" defaultMessage="Community View" />},
    DR: { icon: FaUserFriends, label: <FormattedMessage id="amenity.driversRoom" defaultMessage="Driver's Room" />},
    FF: { icon: FaUtensils, label: <FormattedMessage id="amenity.fullyFittedKitchen" defaultMessage="Fully Fitted Kitchen" />},
    GV: { icon: FaTree, label: <FormattedMessage id="amenity.golfView" defaultMessage="Golf View" />},
    HT: { icon: FaFire, label: <FormattedMessage id="amenity.heating" defaultMessage="Heating" />},
    IS: { icon: FaSwimmingPool, label: <FormattedMessage id="amenity.indoorSwimmingPool" defaultMessage="Indoor Swimming Pool" />},
    LF: { icon: FaBuilding, label: <FormattedMessage id="amenity.onLowFloor" defaultMessage="On Low Floor" />},
    NG: { icon: FaChess, label: <FormattedMessage id="amenity.nearGolf" defaultMessage="Near Golf" />},
    NM: { icon: FaMosque, label: <FormattedMessage id="amenity.nearMosque" defaultMessage="Near Mosque" />},
    MO: { icon: FaSubway, label: <FormattedMessage id="amenity.nearMetro" defaultMessage="Near Metro" />},
    NO: { icon: FaCompass, label: <FormattedMessage id="amenity.northOrientation" defaultMessage="North Orientation" />},
    NS: { icon: FaShoppingCart, label: <FormattedMessage id="amenity.nearSchool" defaultMessage="Near School" />},
    PG: { icon: FaCar, label: <FormattedMessage id="amenity.privateGarage" defaultMessage="Private Garage" />},
    SA: { icon: FaSpa, label: <FormattedMessage id="amenity.spa" defaultMessage="Spa" /> },
    ST: { icon: FaPenFancy, label: <FormattedMessage id="amenity.study" defaultMessage="Study" />},
    TR: { icon: FaTree, label: <FormattedMessage id="amenity.terrace" defaultMessage="Terrace" />},
    IC: { icon: FaBuilding, label: <FormattedMessage id="amenity.withinaCompound" defaultMessage="Within a Compound" />},
    BK: { icon: FaParking, label: <FormattedMessage id="amenity.basementParking" defaultMessage="Basement Parking" />},
    GR: { icon: FaCar, label: <FormattedMessage id="amenity.gardenView" defaultMessage="Garden View" />},
    PT: { icon: FaSubway, label: <FormattedMessage id="amenity.nearPublicTransportation" defaultMessage="Near Public Transportation" />},
    OP: { icon: FaBuilding, label: <FormattedMessage id="amenity.officePartitions" defaultMessage="Office Partitions" />},
    HF: { icon: FaBuilding, label: <FormattedMessage id="amenity.onHighFloor" defaultMessage="On High Floor" />},
    PK: { icon: FaLeaf, label: <FormattedMessage id="amenity.publicParks" defaultMessage="Public Parks" />},
    BT: { icon: FaBuilding, label: <FormattedMessage id="amenity.basement" defaultMessage="Basement" />},
    BC: { icon: FaUmbrellaBeach, label: <FormattedMessage id="amenity.beachAccess" defaultMessage="Beach Access" />},
    AP: { icon: FaPlane, label: <FormattedMessage id="amenity.nearAirport" defaultMessage="Near Airport" />},
    HO: { icon: FaHospital, label: <FormattedMessage id="amenity.nearHospital" defaultMessage="Near Hospital" />},
    RT: { icon: FaUtensils, label: <FormattedMessage id="amenity.nearRestaurant" defaultMessage="Near Restaurant" />},
    VT: { icon: FaDog, label: <FormattedMessage id="amenity.nearVeterinary" defaultMessage="Near Veterinary" />},
    GA: { icon: FaLeaf, label: <FormattedMessage id="amenity.privateGarden" defaultMessage="Private Garden" />},
    PY: { icon: FaDumbbell, label: <FormattedMessage id="amenity.privateGym" defaultMessage="Private Gym" />},
    SR: { icon: FaHotTub, label: <FormattedMessage id="amenity.steamRoom" defaultMessage="Steam Room" />},
    SG: { icon: FaBox, label: <FormattedMessage id="amenity.storageRoom" defaultMessage="Storage Room" />},
    UI: { icon: FaBrush, label: <FormattedMessage id="amenity.upgradedInterior" defaultMessage="Upgraded Interior" />},
    DN: { icon: FaCoffee, label: <FormattedMessage id="amenity.pantry" defaultMessage="Pantry" />, ar: "مخزن" },
    PP: { icon: FaSwimmingPool, label: <FormattedMessage id="amenity.privatePool" defaultMessage="Private Pool" />},
    AN: { icon: FaBlender, label: <FormattedMessage id="amenity.kitchenAppliances" defaultMessage="Kitchen Appliances" />},
    SS: { icon: FaHotTub, label: <FormattedMessage id="amenity.sauna" defaultMessage="Sauna" />},
    RA: { icon: FaConciergeBell, label: <FormattedMessage id="amenity.receptionArea" defaultMessage="Reception Area" />},
    GZ: { icon: GiWoodCabin, label: <FormattedMessage id="amenity.gazebo" defaultMessage="Gazebo" />},
};

const PropertyInfo = ({ property }) => {
  const { isOpen, onToggle } = useDisclosure();

  const initialAmenities = property?.amenities?.slice(0, 6);
  const remainingAmenities = property?.amenities?.slice(6);

  const [isExpanded, setIsExpanded] = useState(false);

  const CHARACTER_LIMIT = 900;
  const description = property?.description_en;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box p={5} maxW="1200px" mx="auto">
      <Box mb={8} pb={20} borderBottom="1px solid #e2e8f0">
        <Text fontSize="4xl" fontWeight="bold">
          {property?.title_en}
        </Text>
      </Box>

      <Box mb={8} pb={20} borderBottom="1px solid #e2e8f0">
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={4}>
          <Flex align="center">
            <Icon as={TbCurrencyDirham} boxSize={10} mr={2} />
            <Text fontSize="2xl">
              AED {property?.price} 
              {property?.rental_period && (
              <>
                <span> / </span>
                <FormattedMessage id="property.rentalPeriod" defaultMessage="Rental Period: " />
                {property?.rental_period === "Y" && (
                  <FormattedMessage id="property.rentalPeriod.yearly" defaultMessage="Yearly" />
                )}
                {property?.rental_period === "M" && (
                  <FormattedMessage id="property.rentalPeriod.monthly" defaultMessage="Monthly" />
                )}
                {property?.rental_period === "W" && (
                  <FormattedMessage id="property.rentalPeriod.weekly" defaultMessage="Weekly" />
                )}
                {property?.rental_period === "D" && (
                  <FormattedMessage id="property.rentalPeriod.daily" defaultMessage="Daily" />
                )}
              </>
            )}
            </Text>
          </Flex>
          <Flex align="center">
            <Icon as={FaLocationArrow} boxSize={6} mr={2} />
            <Text fontSize="lg">
              {property?.community}, {property?.city}
            </Text>
          </Flex>
        </Grid>

        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={4} mt={6}>
          <Flex align="center">
            <Icon as={FaBuilding} boxSize={6} mr={2} />
            <Text fontSize="lg">{property?.property_type} <FormattedMessage id="property.for" defaultMessage="for" /> {property?.offering_type}</Text>
          </Flex>
          <Flex align="center">
            <Icon as={FaEye} boxSize={6} mr={2} />
            <Text fontSize="lg">
              <FormattedMessage id="property.views" defaultMessage="Views:" /> {property?.viewsCount}
            </Text>
          </Flex>
        </Grid>
      </Box>

      <Box mb={8} pb={20} borderBottom="1px solid #e2e8f0">
        <Text fontSize="3xl" mb={4}>
          <FormattedMessage id="property.details" defaultMessage="Property Details" />
        </Text>
        <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={6}>
          {property?.property_type !== "OF" && (
            <Flex align="center">
              <Icon as={FaBed} boxSize={6} mr={2} />
              <Text fontSize="lg">
                {property?.bedroom}{" "}
                <FormattedMessage
                  id={Number(property?.bedroom) === 1 ? "property.bedroom" : "property.bedrooms"}
                  defaultMessage={Number(property?.bedroom) === 1 ? "Bedroom" : "Bedrooms"}
                />
              </Text>
            </Flex>
          )}
          <Flex align="center">
            <Icon as={FaBath} boxSize={6} mr={2} />
            <Text fontSize="lg">
              {property?.bathroom}{" "}
              <FormattedMessage
                id={Number(property?.bathroom) === 1 ? "property.bathroom" : "property.bathrooms"}
                defaultMessage={Number(property?.bathroom) === 1 ? "Bathroom" : "Bathrooms"}
              />
            </Text>
          </Flex>
          <Flex align="center">
            <Icon as={FaBuilding} boxSize={6} mr={2} />
            <Text fontSize="lg">
              {property?.size} <FormattedMessage id="property.sqft" defaultMessage="SQFT" />
            </Text>
          </Flex>
        </Grid>

        <Box mt={4}>
          <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={6}>
            <Flex align="center">
              <Icon as={FaClipboardCheck} boxSize={6} mr={2} />
              <Text fontSize="lg">
                <FormattedMessage id="property.purpose" defaultMessage="Purpose:" />{" "}
                {property?.property_purpose}
              </Text>
            </Flex>
            <Flex align="center">
              <Icon as={FaCouch} boxSize={6} mr={2} />
              <Text fontSize="lg">
                <FormattedMessage id="property.furnished" defaultMessage="Furnished:" />{" "}
                {property?.furnished === "Yes" ? (
                  <FormattedMessage id="property.furnishedYes" defaultMessage="Yes" />
                ) : (
                  <FormattedMessage id="property.furnishedNo" defaultMessage="No" />
                )}
              </Text>
            </Flex>
            <Flex align="center">
              <Icon as={FaCreditCard} boxSize={6} mr={2} />
              <Text fontSize="lg">
                <FormattedMessage id="property.cheques" defaultMessage="Cheques:" />{" "}
                {property?.cheques}
              </Text>
            </Flex>
          </Grid>
        </Box>
      </Box>

      <Box mb={8} pb={6} borderBottom="1px solid #e2e8f0">
        <Accordion allowToggle defaultIndex={[0]}>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontSize="3xl" fontWeight="bold" color="#2D3748">
                <FormattedMessage id="property.description" defaultMessage="Property Description" />
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} fontSize="lg" color="#4A5568">
              <Text noOfLines={!isExpanded ? 3 : null}>
                {isExpanded ? description : `${description?.slice(0, CHARACTER_LIMIT)}...`}
              </Text>
              <Button
                onClick={toggleExpanded}
                variant="link"
                colorScheme="blue"
                mt={2}
                fontSize="md"
                _hover={{ textDecoration: 'underline' }}
              >
                {isExpanded ? "Read Less" : "Read More"}
              </Button>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>

      <Box mb={8}>
      <Grid templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }} gap={6}>
        {initialAmenities.map((amenity, index) => {
          const amenityData = amenitiesMapping[amenity];
          return (
            <Flex key={index} direction="column" align="center">
              <Icon as={amenityData?.icon} boxSize={10} mb={2} />
              <Text fontSize="lg">{amenityData?.label}</Text>
            </Flex>
          );
        })}
      </Grid>

      <Collapse in={isOpen} animateOpacity>
        <Grid templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }} gap={6} mt={4}>
          {remainingAmenities.map((amenity, index) => {
            const amenityData = amenitiesMapping[amenity];
            return (
              <Flex key={index} direction="column" align="center">
                <Icon as={amenityData?.icon} boxSize={10} mb={2} />
                <Text fontSize="lg">{amenityData?.label}</Text>
              </Flex>
            );
          })}
        </Grid>
      </Collapse>

      {remainingAmenities.length > 0 && (
        <Box mt={4} textAlign="center">
          <Button onClick={onToggle} size="lg" variant="outline">
            {isOpen ? (
              <FormattedMessage id="property.showLess" defaultMessage="Show Less" />
            ) : (
              <FormattedMessage id="property.showMore" defaultMessage="Show More" />
            )}
          </Button>
        </Box>
      )}
    </Box>
    </Box>
  );
};

export default PropertyInfo;
