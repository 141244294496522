import { authAxios, Axios } from "../../utils/axiosMiddleware";
import { appendQueryParam } from "../../utils/Helper";

export const fetchPropertyList = (queryParams) => {
  let url = `property-listings`;
  url = appendQueryParam(url, queryParams);

  return Axios.get(`${url}`);
};

export const fetchLikeProperty = (queryParams) => {
  let url = `user-favorite-property`;
  url = appendQueryParam(url, queryParams);
  
  return authAxios.get(`${url}`);
}

export const likeProperty = (body) => {
  return authAxios.post("user-favorite-property", body);
};

export const disLikeProperty = (id) => {
  return authAxios.delete(`user-favorite-property/${id}`);
};

export const getLikeProperty = (id) => {
  return authAxios.get(`user-favorite-property?userId=${id}`);
};
