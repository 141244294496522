import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "../../styles/properties.css";
import axios from "axios";
import { base_url } from "../../constants/constant";
import { setListingQueryParams } from "../../redux/reducers/propertyReducer";
import { useDispatch } from "react-redux";
import ImageCarousel from "./ImageCarousel";
import PropertyInfo from "./PropertyInfo";
import AgentInfo from "./AgentInfo";
import { FormattedMessage } from "react-intl";

export default function PropertyDetails({auth}) {
  const [property, setProperty] = useState();
  const [agent, setAgent] = useState();
  const [spin, setSpin] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const antIcon = <LoadingOutlined style={{ fontSize: 64, color: "#E5BE54" }} spin />;

  const fetchPropertyDetails = useCallback(async () => {
    setSpin(true);
    try {
      const response = await axios.get(`${base_url}/property-listings/${id}`, {
        headers: { "ngrok-skip-browser-warning": true },
      });
      setProperty(response.data);
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to fetch property details.");
    } finally {
      setSpin(false);
    }
  }, [id]);

  const fetchAgentDetails = useCallback(async (userId) => {
    try {
      const response = await axios.get(`${base_url}/users/${userId}`, {
        headers: { "ngrok-skip-browser-warning": true },
      });
      setAgent(response.data);
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to fetch agent details.");
    }
  }, []);

  const handleBackClick = () => {
    dispatch(
      setListingQueryParams({
        skip: 0,
        take: 6,
        currentPage: 1,  
      })
    );
    navigate(-1);
  };

  useEffect(() => {
    fetchPropertyDetails();
  }, [fetchPropertyDetails]);

  useEffect(() => {
    if (property?.userId) {
      fetchAgentDetails(property.userId);
    }
  }, [property, fetchAgentDetails]);

  return (
    <div style={{ padding: "40px 80px 80px 80px" }}> 
      {spin ? (
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "110px" }}>
          <Spin spinning={spin} indicator={antIcon} />
        </div>
      ) : (
        property && (
          <>
            <div className="back-screen" onClick={handleBackClick}>
              <img src="/icons/leftarrowb.png" alt="" />
              <span style={{ marginLeft: "5px" }}>
                <FormattedMessage id="back" />
              </span>
            </div>
            
            <Row justify="center" style={{ marginBottom: "40px" }}>
              <Col span={24}> 
                <ImageCarousel images={property?.file} />
              </Col>
            </Row>
            
            <Row gutter={[16, 32]}>
              <Col xs={24} md={16}> 
                <PropertyInfo property={property} navigate={navigate} />
              </Col>
              <Col xs={24} md={8}> 
                <AgentInfo agent={agent} property={property} auth={auth} />
              </Col>
            </Row>
          </>
        )
      )}
    </div>
  );
}
