import React, { useCallback, useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { Flex, IconButton, Button } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';

const Carousel = ({ children }) => {
  const numItems = React.Children.count(children);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [containerWidth, setContainerWidth] = useState(0);
  const [currentPage, setCurrentPage] = useState(0); 
  const containerRef = React.createRef();

  useEffect(() => {
    const calculateItemsPerPage = () => {
      const containerWidth = containerRef.current?.offsetWidth || 1000;
      setContainerWidth(containerWidth);
      const calculatedItemsPerPage = Math.floor(containerWidth / 360); 
      setItemsPerPage(calculatedItemsPerPage);
    };

    calculateItemsPerPage(); 
    window.addEventListener('resize', calculateItemsPerPage);

    return () => window.removeEventListener('resize', calculateItemsPerPage);
  }, [containerRef]);

  const totalPages = Math.ceil(numItems / itemsPerPage);

  const slide = useCallback((direction) => {
    setCurrentPage((prevPage) => {
      if (direction === 'NEXT') {
        return Math.min(prevPage + 1, totalPages - 1); 
      } else if (direction === 'PREV') {
        return Math.max(prevPage - 1, 0); 
      }
      return prevPage;
    });
  }, [totalPages]);

  const goToPage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => slide('NEXT'),
    onSwipedRight: () => slide('PREV'),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div {...handlers} ref={containerRef} style={{ position: 'relative', overflow: 'hidden', maxWidth: '100%' }}>
      <Flex justifyContent="flex-start" alignItems="center" position="relative" overflow="hidden" mx="auto" maxW="100%">
        <motion.div
          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}
          animate={{ x: -currentPage * (containerWidth / itemsPerPage) }} 
          transition={{ duration: 0.8, ease: 'easeInOut' }}  
        >
          {React.Children.toArray(children).map((child, index) => (
            <motion.div
              key={index}
              layout  
              style={{
                width: '350px',  
                margin: '10px',  
                flexShrink: 0,   
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              {child}
            </motion.div>
          ))}
        </motion.div>
      </Flex>
      <IconButton
        aria-label="Previous"
        icon={<ArrowLeftIcon />}
        position="absolute"
        top="50%"
        left="10px"
        transform="translateY(-50%)"
        onClick={() => slide('PREV')}
        zIndex="1"
        bg="rgba(0, 0, 0, 0.5)"
        _hover={{ bg: 'rgba(0, 0, 0, 0.8)' }}
        color="white"
        isDisabled={currentPage === 0} 
      />
      <IconButton
        aria-label="Next"
        icon={<ArrowRightIcon />}
        position="absolute"
        top="50%"
        right="10px"
        transform="translateY(-50%)"
        onClick={() => slide('NEXT')}
        zIndex="1"
        bg="rgba(0, 0, 0, 0.5)"
        _hover={{ bg: 'rgba(0, 0, 0, 0.8)' }}
        color="white"
        isDisabled={currentPage === totalPages - 1} 
      />
      <Flex justifyContent="center" mt={4}>
        {Array(totalPages)
          .fill(null)
          .map((_, index) => (
            <Button
              key={index}
              onClick={() => goToPage(index)}  
              mx={1}
              borderRadius="50%"
              bg={currentPage === index ? 'black' : 'gray.300'}  
              _hover={{ bg: 'gray.400' }}
              width="8px"
              height="8px"
              minW="8px"
              p={0}
            />
          ))}
      </Flex>
    </div>
  );
};

export default Carousel;
