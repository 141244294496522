import { Box, Text, Image } from '@chakra-ui/react';

const TownPreview = ({ townName, numProperties }) => {
  return (
    <Box 
      position="relative" 
      borderRadius="10px" 
      overflow="hidden" 
      width="400px" 
      height="400px" 
      m="19px"  
      p="9"    
      boxShadow="lg" 
      cursor="pointer" 
    >
      <Image 
        src="/icons/mapView.jpeg" 
        alt={townName} 
        width="100%" 
        height="70%" 
        objectFit="cover" 
      />
      <Box textAlign="center" mt={3} p={3}>
        <Text fontWeight="bold">{townName}</Text>
        <Text fontSize="sm">{numProperties} Properties</Text>
      </Box>
    </Box>
  );
};

export default TownPreview;
