import { authAxios } from "../../utils/axiosMiddleware";
import { appendQueryParam } from "../../utils/Helper";

export const fetchLeads = (queryParams) => {
  let url = `leads`;
  url = appendQueryParam(url, queryParams);

  return authAxios.get(`${url}`);
};

export const getLeadsById = (id) => {
    return authAxios.get(`leads?id=${id}`);
  };

export const deleteLead = (id) => {
    return authAxios.delete(`leads/${id}`);
  };