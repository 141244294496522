import { Axios } from "../../utils/axiosMiddleware";

export const registerUser = (body) => {
  return Axios.post("/auth/register", body);
};

export const LoginUser = (body) => {
  return Axios.post("/auth/login", body);
};

export const EditUser = (userId, updatedData) => {
  const auth = JSON.parse(localStorage.getItem("userData"));
  return Axios.put(`users/${userId}`, updatedData, {
    headers: {
      Authorization: `Bearer ${auth?.access_token}`,
    },
  });
};

export const sendPasswordResetEmail = (body) => {
  return Axios.post("/auth/forgot-password-otp", body);
};

export const verifyOtp = (body) => {
  return Axios.post(`/auth/verify-otp/forgot_password_otp`, body);
};

export const resetPassword = (body) => {
  return Axios.post("/auth/reset-password-otp", body);
};
