import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropertyDetails from "../../components/PropertyDetails";

const PropertyDetailsPage = () => {
  const location = useLocation();
  const [profileData, setProfileData] = useState(null);
  const auth = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    if (location.state) {
      setProfileData(location.state);
    }
  }, [location.state]);

  return <PropertyDetails profileData={profileData} auth={auth} />;
};

export default PropertyDetailsPage;
