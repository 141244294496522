import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';

const Header = () => {
  return (
    <Box>
      <Heading as="h2" size="xl" fontWeight="bold" color="black" mb={2}>
      <FormattedMessage id="explore_towns" defaultMessage="Explore Towns" />
      </Heading>
      <Text fontSize="lg" color="gray.500">
      <FormattedMessage 
          id="explore_communities" 
          defaultMessage="Explore the vibrant communities and picturesque towns where your dream home awaits."
      />
      </Text>
    </Box>
  );
};

export default Header;
