import React, { useState, useCallback } from 'react';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader, MarkerClusterer } from '@react-google-maps/api';
import { Box, IconButton, Text, Link } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';

const FullMapView = React.memo(({ town, propertyList = [], onClose }) => {
  const [selectedProperty, setSelectedProperty] = useState(null); 
  const [selectedClusterProperties, setSelectedClusterProperties] = useState([]); 
  const [showDetailedInfo, setShowDetailedInfo] = useState(false); 

  const { isLoaded } = useJsApiLoader({
    googleMapsClientId: process.env.NEXT_APP_GOOGLE_MAPS_CLIENT_ID, 
  });

  const firstProperty = propertyList.length > 0 ? propertyList[0] : null;
  const mapCenter = firstProperty
    ? {
        lat: firstProperty.geopoints?.coordinates[0] || 24.4539, 
        lng: firstProperty.geopoints?.coordinates[1] || 54.3773, 
      }
    : { lat: 24.4539, lng: 54.3773 }; 

    const handleClusterClick = (cluster) => {
      const markersInCluster = cluster.getMarkers();
      const clusterProperties = markersInCluster.map((marker) => {
        return propertyList.find((prop) => prop.title_en === marker.getTitle());
      });
        
      const uniqueProperties = clusterProperties.filter(
        (property, index, self) =>
        index === self.findIndex((p) => p.id === property.id)
      );
        
      setSelectedClusterProperties(uniqueProperties);
      setShowDetailedInfo(false);
    };
      
  const renderClusterInfo = () => (
    <InfoWindow
      position={mapCenter} 
      onCloseClick={() => setSelectedClusterProperties([])}
    >
      <Box textAlign="center" maxHeight="200px" overflowY="auto" p={2}>
        <Text fontWeight="bold" mb={2}>Properties in this cluster:</Text>
        <Box display="flex" flexDirection="column" gap={2}>
          {selectedClusterProperties.map((property, index) => (
            <Box
              key={index}
              as="button" 
              onClick={() => {
                setSelectedProperty(property);
                setShowDetailedInfo(true); 
              }}
            >
              <Text fontWeight="bold" color="blue.500">
                {property.title_en}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
    </InfoWindow>
  );
  

  const renderDetailedInfo = () => (
    <InfoWindow
      position={{
        lat: selectedProperty.geopoints?.coordinates[0],
        lng: selectedProperty.geopoints?.coordinates[1],
      }}
      onCloseClick={() => {
        setSelectedProperty(null);
        setShowDetailedInfo(false); 
      }}
    >
      <Box>
        <Text fontWeight="bold">{selectedProperty.title_en}</Text>
        <Text>Location: {selectedProperty.community}</Text>
        <Text>Price: AED {selectedProperty.price}</Text>
        <Text>Size: {selectedProperty.size} sqft</Text>
        <Text>Bedrooms: {selectedProperty.bedroom}</Text>
        <Text>Bathrooms: {selectedProperty.bathroom}</Text>
        <Link
          href={`https://www.google.com/maps?q=${selectedProperty.geopoints.coordinates[0]},${selectedProperty.geopoints.coordinates[1]}`}
          isExternal
          color="blue.500"
          mt={2}
        >
          View on Google Maps
        </Link>
      </Box>
    </InfoWindow>
  );

  const renderMarkers = useCallback((clusterer) => (
    propertyList?.map((property) => (
      <Marker
        key={property.id}
        position={{
          lat: property.geopoints?.coordinates[0] || 0,
          lng: property.geopoints?.coordinates[1] || 0,
        }}
        title={property.title_en}
        clusterer={clusterer}
        onClick={() => {
          setSelectedProperty(property);
          setShowDetailedInfo(true);
        }}
      />
    ))
  ), [propertyList]);

  if (!isLoaded) {
    return <div>Loading Map...</div>;
  }

  return (
    <Box position="relative" width="100%" height="100vh">
      <IconButton
        aria-label="Close map view"
        icon={<ArrowBackIcon />}
        position="absolute"
        top="20px"
        left="20px"
        zIndex="10"
        onClick={onClose}
        bg="gray.600"
        color="white"
      />
      <GoogleMap
        center={mapCenter}
        zoom={12}
        mapContainerStyle={{ width: '100%', height: '100%' }}
      >
        <MarkerClusterer 
          options={{
            gridSize: 60, 
            minimumClusterSize: 2,
          }}
          onClick={handleClusterClick}
        >
          {(clusterer) => renderMarkers(clusterer)}
        </MarkerClusterer>
        {selectedClusterProperties.length > 0 && !showDetailedInfo && renderClusterInfo()}
        {showDetailedInfo && selectedProperty && renderDetailedInfo()}
      </GoogleMap>
    </Box>
  );
});

export default FullMapView;
