import React from 'react';
import { Box, Image, Text, Flex, Heading, SimpleGrid } from '@chakra-ui/react';
import { FeatureCard } from '../Feature/FeatureCard';
import { feature_data } from '../../constants/StaticData';
import { FormattedMessage } from 'react-intl';

const Feature = () => {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      backgroundColor="#ffffff"   
      p={{ base: '40px', md: '80px' }}  
      justifyContent="space-between"
      alignItems="center"
      w="100%"
    >
      <Box flex="1.2" p={{ base: '0', md: '30px' }} mb={{ base: '20px', md: '0' }}>
        <Image
          src="/icons/WhyChooseUs.jpeg" 
          alt="Why Choose Us"
          borderRadius="15px"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          width="100%"  
          height="auto"
        />
      </Box>
      <Box flex="1" pl={{ md: '80px' }}>
        <Heading as="h2" size="xl" mb="4">
          <FormattedMessage id="why_chose_us" defaultMessage="Why Choose Us ?" />
        </Heading>
        <Text fontSize="lg" color="gray.600" mb="8">
        <FormattedMessage 
          id="why_choose_us_description" 
          defaultMessage="At SandSeekers, we believe in providing exceptional real estate services with a personalized touch. Our dedicated team is here to guide you through every step of the buying, selling, or renting process, ensuring a seamless and enjoyable experience. Choose us for unparalleled expertise, local knowledge, and a commitment to your satisfaction." 
        />
        </Text>
        <SimpleGrid columns={1} spacing={6}>
          {feature_data?.map((item) => (
            <FeatureCard
              key={item?.title}
              image={item?.image}
              title={item?.title}
              details={item?.details}
              noBorder 
            />
          ))}
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

export default Feature;
