import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Header from './Header';
import PropertyTypeSection from './PropertyTypes';
import PropertyGrid from './PropertyGrid';

const ExploreApartments = () => {
  const [selectedType, setSelectedType] = useState("OF");

  return (
    <Box backgroundColor="#f5f5f5" pt={20} pb={40} px={6}>
      <Box maxWidth="1470px" mx="auto">
        <Flex justifyContent="space-between" alignItems="center" mb={8}>
          <Header />
          <PropertyTypeSection
            selectedType={selectedType}
            onSelectType={setSelectedType}  
          />
        </Flex>
        <PropertyGrid selectedType={selectedType} />
      </Box>
    </Box>
  );
};

export default ExploreApartments;
