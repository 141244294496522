import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { Box, Flex, Grid, Spinner } from "@chakra-ui/react";
import "../../styles/home.css";
import { useQuery } from "@tanstack/react-query";
import {
  fetchPropertyList,
  getLikeProperty,
} from "../../api/Property/propertyApi";
import { setListingQueryParams } from "../../redux/reducers/propertyReducer";
import Header from "../../components/Properties/header";
import PropertyTypes from "../../components/Properties/PropertyTypes";
import { useDispatch } from "react-redux";
import { PropertyCard } from "../Properties/PropertyCard";
import { FormattedMessage } from "react-intl";

const initialStateData = {
  title_en: '',
  propertyType: '',
  propertyPurpose: '',
  bedroom: '',
  bathroom: '',
  minSize: '',
  maxSize: '',
  community: '',
  completion_status: '',
};

const Property = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("sale");
  const [stateData, setStateData] = useState(initialStateData);

  const { data: propertyList, isLoading } = useQuery({
    queryKey: ["Property", selectedType],
    queryFn: () =>
      fetchPropertyList({ take: 6, skip: 0, property_purpose: selectedType }),
    select: (res) => res?.data?.records,
  });

  const auth = JSON.parse(localStorage.getItem("userData"));

  const {
    data: favorite,
    isLoading: favoriteLoading,
    refetch,
  } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["PropertyLikes"],
    queryFn: () => getLikeProperty(auth?.id),
    select: (res) => res?.data?.records,
  });

  const handleViewAll = () => {
    dispatch(
      setListingQueryParams({
        skip: 0,
        take: 6,
        currentPage: 1,
        property_purpose: selectedType,  
      })
    );
    navigate('/properties', { state: { selectedTab: selectedType } });
  };

  return (
    <Box backgroundColor="white" pt={20} pb={40} px={6}>
    <Box maxWidth="1470px" mx="auto">
      <Flex justifyContent="space-between" alignItems="center" mb={8}>
        <Header />
        <PropertyTypes 
        selectedType={selectedType} 
        onSelectType={setSelectedType} 
        />
      </Flex>

      {(isLoading || favoriteLoading) && (
        <Flex justifyContent="center" alignItems="center" height="50px">
          <Spinner
            size="xl"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="#e5be54"
          />
        </Flex>
      )}

      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', xl: 'repeat(3, 1fr)' }} gap={6}>
        {!favoriteLoading &&
          propertyList?.map((item) => {
            const likeArr = favorite?.filter((like) => like?.propertyId === item?.id);
            return (
              <Box key={item?.id}>
                <PropertyCard item={item} liked={likeArr} refetch={refetch} />
              </Box>
            );
          })}
      </Grid>

      <Flex className="property-view-all-button-conatainer">
        <Button
          onClick={() => {
            dispatch(
              setListingQueryParams({
                ...stateData,
                skip: 0,
                take: 6,
                currentPage: 1,
                property_purpose: selectedType,
                propertyType: stateData.propertyType,
              })
            );
            navigate('/properties', { state: {skip: 0, take: 6, selectedTab:  selectedType === 'sale' ? 'Buy' : selectedType === 'rent' ? 'Rent': selectedType, property_purpose: selectedType, propertyType: stateData.propertyType } } );
          }}
        >
          <FormattedMessage id="view_all" />
        </Button>
      </Flex>
    </Box>
  </Box>
);
};

export default Property;
