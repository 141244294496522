import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box, Grid, Text, Spinner } from "@chakra-ui/react";
import { fetchLeads } from "../../api/lead/leadApi";
import { getLikeProperty } from "../../api/Property/propertyApi";
import { PropertyCard } from "./Card";
import { base_url } from "../../constants/constant";
import axios from "axios";
import { FormattedMessage } from "react-intl";

function ContactedProperties({ refetchSavedProperties }) {
  const auth = JSON.parse(localStorage.getItem("userData"));
  const [contactedProperties, setContactedProperties] = useState([]);
  const [loadingProperties, setLoadingProperties] = useState(true);

  const { data: leads, isLoading: leadsLoading, refetch } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["ContactedProperties"],
    queryFn: () => fetchLeads({ userId: auth?.id }),
    select: (res) => res?.data?.records,
  });

  const { data: favoriteProperties } = useQuery({
    enabled: !!auth?.access_token,
    queryKey: ["PropertyLikes"],
    queryFn: () => getLikeProperty(auth?.id),
    select: (res) => res?.data?.records,
  });

  useEffect(() => {
    if (leads) {
      const fetchPropertyDetails = async () => {
        try {
          const properties = await Promise.all(
            leads.map(async (lead) => {
              const response = await axios.get(`${base_url}/property-listings/${lead.propertyId}`, {
                headers: { "ngrok-skip-browser-warning": true },
              });
              return { ...response.data, leadId: lead?.id };
            })
          );
          setContactedProperties(properties);
        } catch (error) {
          console.error("Failed to fetch property details:", error);
        } finally {
          setLoadingProperties(false);
        }
      };

      fetchPropertyDetails();
    }
  }, [leads]);

  const getFavoriteId = (propertyId) => {
    const favorite = favoriteProperties?.find((fav) => fav.Property.id === propertyId);
    return favorite ? [{ id: favorite.id }] : [];
  };

  return (
    <Box>
      <Text fontSize="xl" fontWeight="bold" pt={10}>
        <FormattedMessage id="contactedProperties.title" defaultMessage="Contacted Properties" />
      </Text>
      {leadsLoading || loadingProperties ? (
        <Spinner />
      ) : (
        <Grid templateColumns="1fr" gap={4} mt="4">
          {contactedProperties.map((property) => (
            <PropertyCard
              key={property.id}
              item={property}
              liked={getFavoriteId(property.id)}  
              refetch={refetch}
              refetchSavedProperties={refetchSavedProperties}
              isContacted={true}
              leadId={property.leadId}
            />
          ))}
        </Grid>
      )}
      {contactedProperties.length === 0 && !loadingProperties && (
        <Text>
          <FormattedMessage id="contactedProperties.noContacted" defaultMessage="No contacted properties found." />
        </Text>
      )}
    </Box>
  );
}

export default ContactedProperties;
