import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import { routes } from "./routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { ChakraProvider } from '@chakra-ui/react';
import store from "./redux/store";
import { IntlProvider } from "react-intl";
import ArabicMessages from "./locale/ar.json";
import EnglishMessages from "./locale/en.json";
function App() {
  const queryClient = new QueryClient();
  const lang = JSON.parse(localStorage.getItem("lang"));

  let Language;

  if (lang) {
    Language = "ar";
  } else {
    Language = "en";
  }
  const messages = {
    ar: ArabicMessages,
    en: EnglishMessages,
  };

  return (
    <IntlProvider
      locale={Language}
      messages={messages[Language]}
      defaultLocale="en"
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider>
          <Routes>
            <Route>
              {routes?.map((route) => {
                return (
                  <Route
                    key={route?.path}
                    path={route?.path}
                    element={route?.component}
                  />
                );
              })}
            </Route>
          </Routes>
          <Footer />
          </ChakraProvider>
        </QueryClientProvider>
      </Provider>
    </IntlProvider>
  );
}

export default App;
