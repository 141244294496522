import React from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setListingQueryParams } from '../../redux/reducers/propertyReducer'; 
import { FormattedMessage } from 'react-intl';

const CallToAction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBrowsePropertiesClick = () => {
    dispatch(
      setListingQueryParams({
        skip: 0,
        take: 6,
        currentPage: 1,
        property_type: 'AP',  
      })
    );
    navigate('/properties');
  };

  return (
    <Box
      backgroundImage="url(/icons/sofa.jpeg)"
      backgroundSize="cover"
      backgroundPosition="center"
      height="588.81px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      px={4}
    >
      <Flex
        direction="column"
        align="center"
        textAlign="center"
        p={8}
        maxW="1000.25px"
        width="100%"
      >
        <Text color="white" fontSize="md" fontWeight="medium" mb={2} letterSpacing="widest">
          <FormattedMessage id="buy_or_sell" />
        </Text>
        <Heading color="white" fontSize="4xl" fontWeight="bold" mb={4}>
        <FormattedMessage id="buy_or_sell_description" />
        </Heading>
        <Heading color="white" fontSize="4xl" fontWeight="bold" mb={6}>
        <FormattedMessage id="awesome_solution" />
        </Heading>
        <Flex gap={4} mt={4}>
          <Button
            backgroundColor="white"
            color="black"
            px={9}
            py={6}
            borderRadius="8px"
            fontSize="md"
            fontWeight="bold"
            position="relative"
            _hover={{ bg: '#f0f0f0' }}
            pr={12}
            onClick={handleBrowsePropertiesClick} 
          >
            <FormattedMessage id="browse_properties" />
            <Box
              as="span"
              position="absolute"
              width="15px"
              height="15.94px"
              right="20px"
              top="50%"
              transform="translateY(-50%)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
              </svg>
            </Box>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CallToAction;
