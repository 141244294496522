import React, { useRef } from "react";
import { Box, Text, Flex, Avatar } from "@chakra-ui/react";
import Header from "./Header";
import { useQuery } from "@tanstack/react-query";
import Carousel from "../common/Carousal"; 
import { Axios } from "../../utils/axiosMiddleware";
import { bucket_base_url } from "../../constants/constant";

export default function Testimonial() {
  const carouselRef = useRef();

  const { data: testimonials } = useQuery({
    queryKey: ["testimonial"],
    queryFn: () => Axios.get("/testimonials"),
    select: (res) => res?.data?.records,
  });

  return (
    <Box py={12} px={5} bg="gray.50" maxWidth="100%" mx="auto">
      <Box mb={10} ml={20} pl={20} mt={10} textAlign="left">
        <Header />
      </Box>
      {testimonials?.length > 0 && (
        <Box position="relative" maxWidth="1850px" mx="auto">
          <Carousel ref={carouselRef}>
            {testimonials?.map((item) => (
              <Box
                key={item?.id}
                bg="white"
                p={6}
                mx={4}
                borderRadius="lg"
                boxShadow="lg"
                maxW="350px"
                minH="400px"  
                textAlign="left"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box>
                  <Text mb={4} fontSize="2xl" fontWeight="bold">
                    {item?.reviewTitle}
                  </Text>
                  <Text mb={4} fontSize="md" fontWeight="bold">
                    "{item?.reviewText}"
                  </Text>
                </Box>
                <Text mb={4} color="yellow.400" fontSize="lg">
                  {"★".repeat(item?.rating)}{" "}
                  {"☆".repeat(5 - item?.rating)}
                </Text>
                <Flex alignItems="center" mt="auto" pt={4} borderTop="1px solid" borderColor="gray.200">
                  <Avatar
                    src={`${bucket_base_url}${item?.file?.filePath}`}
                    alt={item?.clientName}
                    size="lg"
                    mr={4}
                  />
                  <Box>
                    <Text fontSize="md" fontWeight="bold">{item?.clientName}</Text>
                    <Text fontSize="md" color="gray.500">
                      {item?.designation || "Client"}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            ))}
          </Carousel>
        </Box>
      )}
    </Box>
  );
}
